

import vClickOutside from '@/directives/click-outside'

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive('click-outside', vClickOutside)
})

// const clickOutside = {
//   beforeMount: (el, binding, vnode) => {
//     el.clickOutsideEvent = function (event) {
//       if (!(el == event.target || el.contains(event.target))) {
//         if(binding.value)
//           binding.value(); 
//       }
//     };
//     document.body.addEventListener('click', el.clickOutsideEvent)
//   },
//   beforeUnmount: (el) => {
//     document.body.removeEventListener('click', el.clickOutsideEvent)
//   },
// };

// export default defineNuxtPlugin((nuxtApp) => {
//   nuxtApp.vueApp.directive('click-outside', clickOutside)
// })
