

export default defineNuxtPlugin(nuxtApp => {  
  
  var helper = {
  
    extractLinkId: function(str){
      var str = str ? str : '';
      var spl = str.split('-');    
      if(spl.length)
        return spl[0];
      else
        return null      
    }, 
  }

  return {
    provide: {
      helper: helper,
    },
  };

})