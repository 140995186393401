import { useScrollLock } from '@vueuse/core'
import { ref } from 'vue'

export default defineNuxtPlugin(nuxtApp => {  

  let isLocked = ref(false);

  if (process.client) {
    isLocked = useScrollLock(document.body);
  }

  const noscroll = {
    on() {
      if (process.client) {
        isLocked.value = true;
      }
    },
    off() {
      if (process.client) {
        isLocked.value = false;
      }
    },
  }
  
  return {
    provide: {
      noscroll: noscroll,
    },
  };

})