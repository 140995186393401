
export default defineNuxtPlugin(async nuxtApp => {  

  const  router  = useRouter();
  
  

  const err = {
    
    send: (bd, hk) => { 

      // if(typeof bd == 'object'){
        bd = JSON.stringify(bd);
      // }


      nuxtApp.$api.postErrorNx({
        message: bd,
        curUrl: err.getCurUrl(),
        prevUrl: err.getPrevUrl(),
        moment: hk,
        process: import.meta.server ? 'server' : 'client',
        userAgent: import.meta.client ? navigator?.userAgent : '-',
        user: useAuth().getId(),
        pixelRatio: import.meta.client ? window.devicePixelRatio : '-',
        width: import.meta.client ? window.innerWidth : '-',
        height: import.meta.client ? window.innerHeight : '-',
        storeKeys: err.getStoreKeys(),
        cookieKeys: err.getCookieKeys(),
        authToken: useCookie('auth:token',{maxAge: 60 * 60 * 24 * 31 * 6}).value,
        authGuest: useCookie('auth:guest',{maxAge: 60 * 60 * 24 * 31 * 6}).value,
      }).then((res) => {

      }).catch(function (error) {

      });
    },
    getCookieKeys: () => {
      if(import.meta.client && document.cookie){
        var keys = document.cookie.split(';').map((item) => {
          return item.split('=')[0];
        });
        if(keys?.length){
          return keys.join(', ');
        }else{
          return '-';
        }
      }
    },
    getStoreKeys: () => {
      if(import.meta.client && localStorage){
        var keys = Object.keys(localStorage)
        if(keys?.length){
          return keys.map((key) => {
            return key
          }).join(', ');
        }else{
          return '-';
        }
      }
    },
    getPrevUrl: () => {
      if(!router?.options?.history) 
        return '';
      return router.options.history.state?.back;
    },
    getCurUrl: () => {
      if(!router.options.history) 
        return '';
      return router.options.history.state?.current;
    },

  }


  nuxtApp.hook('app:error', (error) => {
    // console.log('app:error');
    console.log(error); 
    var data = {
      message: error?.message,
      name: error?.name,
      stack: error?.stack,
    };
    err.send(data, 'app:error');
  });

  nuxtApp.hook('app:error:cleared', (error) => {
    // console.log('app:error:cleared');
    console.log(error); 
    var data = {
      message: error?.message,
      name: error?.name,
      stack: error?.stack,
    };
    err.send(data, 'app:error:cleared');
  });

  nuxtApp.hook('vue:error', (error, target, info) => {
    // console.log('vue:error');
    console.log(error); 
    // console.log(error.message, error.name, error.stack);

    var data = {
      message: error?.message,
      name: error?.name,
      stack: error?.stack,
    };
    err.send(data, 'vue:error', info);
  });
 

  return {
    provide: {
      err: err,
    },
  };

})