export const advHeader = {
  list: [],
  cookieNameViewedPackages: 'ah_v_packages',
  cookieNameViewedPackagesBanners: 'ah_v_packages_banners',
  packageImpressions: {},
  bannerImpressions: {},
  isReadyHeaderApply: function(){
    if(document.querySelector('.header_add'))
      return false
    return true
  },
  filterPackageBannerBySection: function(packages, section){
    return packages?.filter((itemPackage) => {
      return itemPackage.banners?.filter((itemBanner) => {
        if(this.isBannerHidden('header', itemBanner.id))
          return false;
        return itemBanner.sections.indexOf(section) >= 0;
      }).length
    })
  },
  clickHeader: async function(id){     
    // useNuxtApp().$api.postAdvClickHeader(id,  this.getSection());       
    useAdvStat().value.push({
      id: id,
      type: 'header',
      section: this.getSection(),
      action: 'click',
    })
    // useNuxtApp().$adv.sendStat();
    useNuxtApp().$ga.clickBanner('header');     
  }, 
  viewHeader: async function(id){     
    // console.log('viewHeader');
    // useNuxtApp().$api.postAdvViewHeader(id,  this.getSection());    
    
    useAdvStat().value.push({
      id: id,
      type: 'header',
      section: this.getSection(),
      action: 'view',
    })
    // useNuxtApp().$adv.sendStat();
    this.addImpression(id);
    this.addViewedBanner(id);
    this.addViewedPackage(this.getPackageIdByBannerId(id));
  },
  addImpression: function(id){
    
    if(!this.list.length)
      return false;

    var pack = this.list.find((item) => {
      return item.banners?.length && item.banners.find((itemBanner) => {
        return itemBanner.id == id;
      });
    });

    if(!pack)
      return false;

    pack.sys_impression++;

    var itemBanner = pack.banners.find((itemBanner) => {
      return itemBanner.id == id;
    });

    if(!itemBanner)
      return false;
    
    itemBanner.sys_impression++;

    // console.log('addImpression cpm_box:', itemBanner);
  },
  getPackagesIds: function(){
    var packages = this.list;
    var ids = [];
    for(var itemPackage of packages){
      ids.push(itemPackage.id);
    }
    return ids;
  },
  getPackageIdByBannerId: function(id){
    console.log('packages',this.list);
    var packages = this.list;
    for(var itemPackage of packages){
      for(var itemBanner of itemPackage.banners){
        if(itemBanner.id == id)
          return itemPackage.id;
      }
    }
    return null;
  },
  isCurrentPackagesAndViewedChanged: function(){
    var packages = this.getPackagesIds();
    var viewed = this.getViewedPackages();
    if(packages.length != viewed.length)
      return true;
    for(var id of packages){
      if(viewed.indexOf(id) < 0)
        return true;
    }
    return false;
  }, 
  getViewedPackages: function(){
    var viewed = useCookie(this.cookieNameViewedPackages,{maxAge: 60 * 60 * 24 * 31 * 6}).value ?? '';
    viewed += '';
    if(!viewed)
      return [];
    if(viewed.indexOf(',') < 0)
      var ret = [viewed];
    else
      var ret = viewed.split(',');

    return ret.map((item) => {
      return Number(item);
    });
  },
  setViewedPackages: function(list){
    if(!list?.length)
      return;
    useCookie(this.cookieNameViewedPackages,{maxAge: 60 * 60 * 24 * 31 * 6}).value = list.join(',');
  },
  getViewedPackagesBanners: function(){
    var viewed = useCookie(this.cookieNameViewedPackagesBanners,{maxAge: 60 * 60 * 24 * 31 * 6}).value ?? '';
    viewed += '';
    if(!viewed)
      return [];
    if(viewed.indexOf(',') < 0)
      var ret = [viewed];
    else
      var ret = viewed.split(',');

    return ret.map((item) => {
      return Number(item);
    });
  },
  setViewedPackagesBanners: function(list){
    if(!list?.length)
      return;
    useCookie(this.cookieNameViewedPackagesBanners,{maxAge: 60 * 60 * 24 * 31 * 6}).value = list.join(',');
  },
  removeViewedPackage: function(id, list){
    return list.filter((item) => {
      return item != id;
    })
  },
  addViewedPackage: function(id){
    if(!id) return;
    id = Number(id);
    var viewed = this.getViewedPackages();
    // console.log('getViewedPackages', viewed);
    viewed = this.removeViewedPackage(id, viewed);
    // viewed.unshift(id);
    viewed.push(id);
    this.setViewedPackages(viewed);
  },
  addViewedBanner: function(id){
    if(!id) return;
    id = Number(id);
    var viewed = this.getViewedPackagesBanners();
    // console.log('getViewedPackages', viewed);
    viewed = this.removeViewedPackage(id, viewed);
    // viewed.unshift(id);
    viewed.push(id);
    // console.log('getViewedPackages', viewed);
    this.setViewedPackagesBanners(viewed);
  },
  isBannerHidden: function(type, id){   

    var hidden = useCookie('adv_hidden_' + type,{maxAge: 60 * 60 * 24 * 31 * 6}).value ?? '';

    if(!hidden.length)
      return false;
    
    return hidden.split(',').filter((item) => {
      return item == id ? true : false;
    }).length
  },    
  getSection: function(){              
    // var path = router.currentRoute.value.matched[0].path;
    const router = useRouter(); 
    var path = router.currentRoute.value.fullPath;
    path = path.split('/');
    var section = path[1] ? path[1] : 'index';
    return section;
  },
  filterBannerBySection: function(packages, section){
    return packages.map((itemPackage) => {
      itemPackage.banners = itemPackage.banners?.filter((itemBanner) => {
        if(this.isBannerHidden('header', itemBanner.id))
          return false;
        return itemBanner.sections.indexOf(section) >= 0;
      })
      return itemPackage;
    })
  },
  sortPackageByImpressions: function(packages){
    return packages.sort((a, b) => {
      return  a.sys_impression - b.sys_impression;
    })
  },
  sortPackageBannersByViews: function(banners){
    var viewed = this.getViewedPackagesBanners();
    if(!viewed.length)
      return banners;
    
    return banners.sort((a, b) => {
      return viewed.indexOf(a.id) - viewed.indexOf(b.id);
    })
  },
  sortPackageByViews: function(packages){
    var viewed = this.getViewedPackages();
    if(!viewed.length)
      return packages;
    
    return packages.sort((a, b) => {
      // return  viewed.indexOf(a.id) - viewed.indexOf(b.id);
      // console.log('sort');
      // console.log(a.id);
      // console.log(viewed.indexOf(a.id));
      return  viewed.indexOf(a.id) - viewed.indexOf(b.id);
    })
  },
  sortBannersByImpressions: function(packages){
    return packages.map((itemPackage) => {
      itemPackage.banners = itemPackage.banners.sort((a, b) => {
        return  (this.bannerImpressions[a.id] ?? 0) - (this.bannerImpressions[b.id] ?? 0);
      })
      return itemPackage;
    })
  },
  addBannerHidden: function(type, id){
    var hidden = useCookie('adv_hidden_' + type,{maxAge: 60 * 60 * 24 * 31 * 6}).value;
    hidden = hidden ?? '';
    hidden += ',' + id;
    useCookie('adv_hidden_' + type,{maxAge: 60 * 60 * 24 * 31 * 6}).value = hidden;
  },
  setList: function(){
    const { advList } = useAdvList();
    // console.log('advList.value',advList.value);
    if(!advList.value?.items_header)
      return [];
    this.list = advList.value?.items_header;    
  },
  getHeaderBanner: function(){

    if(this.isIgnoreSection())
      return false;

    if(this.isIgnorePages())
      return false;

    var section = this.getSection();
    // console.log('header banner selected');
    // console.log('this.bannerImpressions');
    // console.log(this.bannerImpressions); 
    
    if(!this.list)
      return null;


    var packagesSection = this.filterPackageBannerBySection(this.list, section);
    var packagesBannersSection = this.filterBannerBySection(packagesSection, section);

    packagesBannersSection = this.sortPackageByImpressions(packagesBannersSection);
    packagesBannersSection = this.sortPackageByViews(packagesBannersSection);
    packagesBannersSection = this.sortBannersByImpressions(packagesBannersSection);

    if(!packagesBannersSection.length)
      return null;

    if(packagesBannersSection[0] && packagesBannersSection[0].banners[0]){
      
      let hPackage = packagesBannersSection[0];
      hPackage.banners = this.sortPackageBannersByViews(hPackage.banners);
      let hBanner = hPackage.banners[0];
      this.bannerImpressions[hBanner.id] = (this.bannerImpressions[hBanner.id] ?? 0) + 1;
      this.packageImpressions[hPackage.id] = (this.packageImpressions[hPackage.id] ?? 0) + 1;
      return hBanner;
    }

    return null;
  },
  isIgnorePages: function(){   
    const router = useRouter(); 
    var path = router.currentRoute.value.fullPath;
    var ignore = [
      'diaries/[\\w-]+/edit/week',
      'grower/[\\w-]+/settings',
      'diaries/edit',
      'brands-manager',
    ];
    ignore = ignore.map((item) => {
      return new RegExp(item);
    });
    for(var item of ignore){
      if(item.test(path))
        return true;
    }
    return false;
  },
  isIgnoreSection: function(){   
    const router = useRouter(); 
    var path = router.currentRoute.value.fullPath;
    const { advList } = useAdvList();
    
    if(!advList.value?.ignore_sections)
      return false;
    // console.log('advList.value.ignore_sections');
    // console.log(path);
    var ignore_sections = advList.value.ignore_sections;
    for(var ignore_section of ignore_sections){
      if(path.indexOf(ignore_section) >= 0){
        return true;
      }
    }
    return false;
    
  },
}