

export default defineNuxtPlugin(nuxtApp => {  
  
  const { error:errorPopup, success: successPopup, add: addPopup, remove: removePopup } = usePopupStore();

  var popup = {    
    add: addPopup,
    error: errorPopup,
    success: successPopup,
    remove: removePopup,
  } 

  return {
    provide: {
      popup: popup,
    },
  };

})