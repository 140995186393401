
import {header_seo} from '@/lang/headers/en.js'

export default defineNuxtPlugin(nuxtApp => {  

  var def = {
    section: '',
    title: '',
  };
  

  var head = {

    router: useRouter(),
    route: useRoute(),
    t: nuxtApp.$i18n.t,    
    currentData: null,
    currentMeta: null,
    currentJson: null,
    comments: [],
 
    getHost: function(){      
      return 'https://growdiaries.com';
    },
    getCurrentUrl: function(){
      // return this.router.currentRoute.value.fullPath;
      return this.route.fullPath;
    },
    getPath: function(){
      // console.log('getPath');
      // console.log(this.router.currentRoute.value);

      var cur = this.router.currentRoute.value;
      // router.currentRoute.value
      // return this.router.currentRoute.value.matched[this.router.currentRoute.value.matched.length-1].path;
      if(!cur.matched?.length){
        return '';
      }
      // console.log(cur.matched[cur.matched.length-1].path);
      return cur.matched[cur.matched.length-1].path;
    },
    isExistsParamsTag: function(){
      // console.log('if(this.router.currentRoute.value.params?.tag', this.route);
      if(this.router.currentRoute.value.params?.tag?.length){
        return true;
      }
      if(this.router.currentRoute.value.query?.tags){
        return true;
      }
      return false;
    },
    cnv: function(path){
      // console.log('path');
      // console.log('path before', path);
      
      if(!this.isExistsParamsTag()){
        path = path.replace('/:tag(.*)*', '');
      }

      var regex = /(:[a-z]|\*)\w+/g;
      path = path.replace(regex, '$$$$');
      /* custom replace+ */
      path = path.replace('(.*)*', ''); // custom replace
      path = path.replace('brands-manager', 'brand-manager'); // custom replace for b2b
      /* custom replace- */
      var regex = /(\/)$/g;
      path = path.replace(regex, '');
      path = path.replace(/\(\)/g, ''); 
      path = path.replace('$$?', '$$$$'); // need check
      path = path ? path : '/';
      
      // console.log('path after', path);
      // console.log('path after roeuter', this.router);
      // console.log('path after roeuter', this.route);
      return path;
    },
    getItem: function(path){
      var list = [];
      if(typeof header_seo != 'undefined'){
        list = header_seo;
      }else{
        // console.log('header_seo WRONG!');
      }
      for(var i of list){
        if(i.section == path){
          // console.log('seo head def', i);
          return i;
        }
      }
      
      return def;
    },
    getCurrentItem: function(){
      // console.log(this.getPath());
      var path = this.cnv(this.getPath());
      var item = this.getItem(path);       
      return item;
    },
    replaceParams: function(str, data){  

      // console.log('replaces header');
      // console.log(str);
      str = str || '';

      var list = str.split(/\{|\}(, *)?/);            
      for(var a of list){
        if(typeof data[a] !== 'undefined'){
          if(a == 'page'){
            if(data[a] > 1){
              str = str.replace('{' + a + '}', data[a]);
            }else{
              str = str.replace('p{page}', '');
              str = str.replace('с{page}', '');
            }
          }else if(a == 'n'){
            if(data[a] > 1){
              str = str.replace('{' + a + '}', data[a]);
            }else{
              str = str.replace(' {n}', '');
            }
          }else{
            str = str.replace('{' + a + '}', data[a]);
          }
        }else{
          str = str.replace('{' + a + '}', '');
        }
      }
      // console.log('replaces header');
      // console.log(str);
      // console.log(list);
      return str;
    },   
    getTitle: function(data){   
      
      return this.replaceParams(this.getCurrentItem().title, data); 
    }, 
    getDescription: function(data){      
      return this.replaceParams(this.getCurrentItem().description, data); 
    }, 
    getMeta: function(data, meta){      
      return [
         { hid: 'description', name: 'description', content: this.getDescription(data) }
      ].concat(meta || []) 
    },
    getScript: function(data, json){ 
      return [
         {
          children: JSON.stringify(
            [
              {
                "@context": "http://schema.org",
                "@type": "WebSite",
                "name": "GrowDiaries",
                "alternateName": "GrowDiaries",
                "url": "https://growdiaries.com/"
              }
            ].concat(json || [])
           ), 
           type: 'application/ld+json'
         }
        ] 
    },
    getCanonicalLink: function(){ 

      let href = '';
      let host = this.getHost();
      let url = this.getCurrentUrl();

      //if(url.indexOf('/seedbank/dinafem/') != -1){
      //  href = host + url.replace(/(\/diaries)|(\/gallery)|(\/growers)|(\/harvests)|(\/strains)|(\/reviews)/, "");
      //}else if(url.indexOf('/week') != -1){
      //  href = host + url.replace(/\/week\/.*/, "");
      //}else{
      //  href = host + url;
      //}
      
      if(url.match(/(\/seedbank\/)|(\/nutrients\/)|(\/grow-lights\/)|(\/grow-tents\/)|(\/growers\/)/)){
        href = host + url.replace(/(\/diaries)|(\/gallery)|(\/growers$)|(\/harvests)|(\/strains)|(\/reviews)/, "");
      }else if(url.indexOf('/week') != -1){
        href = host + url.replace(/\/week\/.*/, "");
      }else{
        href = host + url;
      } 

      return [
          {rel: 'canonical', href: href}
      ] 
    }, 
    get: function(data, meta, json){  
      return {
        title: this.getTitle(data),
        meta: this.getMeta(data, meta),
        script: this.getScript(data, json),
        link: this.getCanonicalLink()
      } 
    }, 

    // diary
    getSeedsString: function(data, is_full){  

      if(!data?.items_equip?.length){
        return '';
      }



      var is_full = is_full || false;
      var list = [];
      for(var s of data.items_equip.filter(s => s.category == 'seed')){
        if(data.items_equip.length > 1 && !is_full){
          list.push(s.name);
        }else{
          list.push(s.item_brand.name + ' ' + s.name);
        }
      }
      return list.join(', ');
    },     
    getHarvestComment: function(data){      
      
      if(!data?.items_week?.length){
        return '';
      }

      for(var w of data.items_week){
        if(w.faza == 2){
          return w.item_harvest.strain_comment;
        }
      }
      return '';
    },
    getWeekName: function(diary, week){ 

      if(!diary?.items_week?.length){
        return '';
      }

      var current_id = week ? week.id : diary.current_id;

      var aWeeks = {};

      var res = '';

      var curNum = '';
      var curSub = '';

      for(var w of diary.items_week){
        var num = w.faza == 2 ? 'harvest' : 'week' ;
        var numWeek = num + w.days;
        if(!aWeeks[numWeek]){
          aWeeks[numWeek] = 1;
        }else{
          aWeeks[numWeek]++;
        }
        if(current_id == w.id){
          curNum = numWeek;
          curSub = aWeeks[numWeek];
        }
      }

      if(aWeeks[curNum] > 1){
        res = curNum + '.' + curSub;

      }
      else{
        res = curNum;
      }

      return res;
    },
    getShorts: function(data){ 

      // console.log('seo data');
      // console.log(data);

      if(!data)
        return {}; 
      
      return this.get(
        {
          shortsname: data?.source_name,
          authorname: data?.name,
        },
        '',
        {
          "@context": "https://schema.org",
          "@type": "CreativeWork",
          "name": data.name,
          "creator": {
            "@type": "Thing",
            "url": this.getHost() + data?.link
          }
        }
      );
    },
    setCommentsForDiary: function(comments, comments_count){ 
      // console.log('-----3');


      var json = this.currentJson;
      json.interactionStatistic = {
        "@type": "InteractionCounter",
        "interactionType": "https://schema.org/LikeAction",
        "userInteractionCount": comments_count
      };
      json.comment = this.getCommentsForDiary(comments);
    

      return this.get(
        this.currentData,
        this.currentMeta,
        json
      );
 
    },
    getCommentsForDiary: function(comments){
      // console.log('-----2-' + this.comments.length);
      if(comments.length){
 
        var ret = [];
        for(var c of comments){
          if(c.text){
              var row =
                {
                  "@type": "Comment",
                  "datePublished": c.add_date + '+02:00',
                  "text": c.text,
                  "author": {
                    "@type": "Person",
                    "name": c.item_user.name,
                    "url": this.getHost() + c.item_user.link,
                    "agentInteractionStatistic": {
                      "@type": "InteractionCounter",
                      "interactionType": "https://schema.org/WriteAction",
                      "userInteractionCount": c.cnt_childs
                    }
                  },
                  "agentInteractionStatistic": {
                    "@type": "InteractionCounter",
                    "interactionType": "https://schema.org/LikeAction",
                    "userInteractionCount": c.cnt_likes
                  }
                };

                if(c.replies?.items_comment?.length){              

                  row.comment = [];
                  for(var r of c.replies.items_comment){
                    if(r.text){
                        row.comment.push({
                          "@type": "Comment",
                          "datePublished": r.add_date + '+02:00',
                          "text": r.text,
                          "author": {
                            "@type": "Person",
                            "name": r.item_user.name,
                            "url": this.getHost() + r.item_user.link,
                          },
                          "agentInteractionStatistic": {
                            "@type": "InteractionCounter",
                            "interactionType": "https://schema.org/LikeAction",
                            "userInteractionCount": r.cnt_likes
                          }
                        });
                    }
                  }
                }
            }

            ret.push(row);
          
        }
        return ret;
      }
      
      return [];
    },
    getForDiary: function(data, week, deindex){ 

      // console.log('-----1');
      // console.log('sdfsdf', data.wewe);
      // return;
      // console.log('getForDiary');
      // console.log(data);
      if(!data?.id)
        return {}; 

      
              
      this.currentData = {
        page: 1,
        weekname: this.getWeekName(data, week),
        authorname: data?.item_user?.name ? data?.item_user?.name : 'Removed',
        diaryname: data?.name,
        singlestrain: data?.items_seed?.length > 1 ? '' : '.Cannabis strain.',
        strainnames_commaseparated: this.getSeedsString(data) + ((data?.dublicate_num ? ' ' + data?.dublicate_num : '')),
        strainnames_commaseparated_full: this.getSeedsString(data, true) + ' ' + data?.dublicate_num,
        params_room_type: data?.type_room,
        params_substrate: data?.type_soil,
        authorcomment: this.getHarvestComment(data),
      };
      
      console.log('this.currentData');
      console.log(this.currentData);

      this.currentMeta = deindex ?
      [
        { hid: 'robots', name: 'robots', content: 'noindex' }
      ] : [];
      
      this.currentJson = {
        "@context": "https://schema.org",
        "@type": "DiscussionForumPosting",
        "mainEntityOfPage": this.getHost() + '/diaries/' + data.link,
        "url": this.getHost() + '/diaries/' + data.link,
        "image": data.avatar,
        "headline": data.name,
        "text": this.getHarvestComment(data),
        "datePublished": data.add_date + '+02:00',
        "author": {
          "@type": "Person",
          "name": data?.item_user?.name,
          "url": this.getHost() + data?.item_user?.link
        }          
      };

      return this.get(
        this.currentData,
        this.currentMeta,
        this.currentJson
      );
      
      // return this.get(
      //   {
      //     page: 1,
      //     weekname: this.getWeekName(data, week),
      //     authorname: data?.item_user?.name ? data?.item_user?.name : 'Removed',
      //     diaryname: data?.name,
      //     singlestrain: data?.items_seed?.length > 1 ? '' : '.Cannabis strain.',
      //     strainnames_commaseparated: this.getSeedsString(data) + ((data?.dublicate_num ? ' ' + data?.dublicate_num : '')),
      //     strainnames_commaseparated_full: this.getSeedsString(data, true) + ' ' + data?.dublicate_num,
      //     params_room_type: data?.type_room,
      //     params_substrate: data?.type_soil,
      //     authorcomment: this.getHarvestComment(data),
      //   },
      //   deindex ?
      //   [
      //     { hid: 'robots', name: 'robots', content: 'noindex' }
      //   ] : [],
      //   {
      //     "@context": "https://schema.org",
      //     "@type": "DiscussionForumPosting",
      //     "mainEntityOfPage": '/diaries/' + data.link,
      //     "headline": data.name,
      //     "text": this.getHarvestComment(data),
      //     "datePublished": data.add_date.substring(0, 10),
      //     "author": {
      //       "@type": "Person",
      //       "name": this.getHost() + data?.item_user?.name,
      //       "url": this.getHost() + data?.item_user?.link
      //     }          
      //   }
      // );
    },
    getForDiaryEdit: function(){ 
      return this.get({
      });
    },

    // journal
    getJournals: function(){ 
      return this.get({
        page: 1
      });
    },
    getJournal: function(data){
      
      if(!data?.id) 
        return {};

      return this.get(
        {
          page: 1,
          post_title: data.title, 
          post_preview: data.preview
        },
        [

          { hid: 'og:type', property: 'og:type', content: 'article'},
          { hid: 'og:title', property: 'og:title', content: data.title},
          { hid: 'og:description', property: 'og:description', content: data.preview},
          { hid: 'og:url', property: 'og:url', content: data.link},
          { hid: 'og:image', property: 'og:image', content: data.cover},

          { hid: 'twitter:card', property: 'twitter:card', content: 'summary_large_image'},
          { hid: 'twitter:title', property: 'twitter:title', content: data.title},
          { hid: 'twitter:description', property: 'twitter:description', content: data.preview},
          { hid: 'twitter:url', property: 'twitter:url', content: data.link},
          { hid: 'twitter:image', property: 'twitter:image', content: data.cover}

        ],
        {
          "@context": "https://schema.org",
          "@type": "Article",
          "name": data.title,
          "headline": data.preview,
          "image": data.cover,
          "publisher": {
            "@type": "Organization",
            "name": "GrowDiaries' Reviewer",
            "url": this.getHost(),
            "logo": {
              "@type": "ImageObject",
              "url": "/logo/logo-light.svg",
              "image": "/logo/logo-light.svg"
            }
          },
          "author": {
            "@type": "Person",
            "name": "GrowDiaries"
          },
          "datePublished": data.view_date.substring(0, 10),
          "dateModified": data.view_date.substring(0, 10),
          "mainEntityOfPage": this.getHost() + data.link
        }
      );
    },
    getJournalsTag: function(tag, blogs_count){ 
      return this.get({
        tagname: tag,
        posts_count: blogs_count,
        page: 1
      });
    },
    getJournalsCategory: function(category, blogs_count){ 
      return this.get({
        tagname: category,
        posts_count: blogs_count,
        page: 1
      });
    },
    getJournalVotes: function(){ 
      return this.get({
        page: 1
      });
    },
    getJournalVote: function(data){ 
      if(!data || !data.id) return {};
      return this.get({
        page: 1,
        name: data.title
      });
    },

    // grower
    getGrowerList: function(data){ 
      if(!data || !data.id) return {};
      return this.get({
        page: 1
      });
    },
    getGrower: function(){ 
      return this.get({
        page: 1
      });
    },
    getGrowerInfo: function(data){ 
      if(!data || !data.id) return {};
      return this.get(
        {
          page: 1,
          growername: data.name,
          favbreeder: data.item_growing ? data.item_growing.fvs_name : '',
          favnutrient: data.item_growing ? data.item_growing.fvn_name : '',
          favmethod: data.item_growing ? data.item_growing.fav_method : ''
        },
        '',
        {
          "@context": "https://schema.org",
          "@type": "Person",
          "name": data.name,
          "url": this.getHost() + data.link,
          "image": data.avatar_small
        }
      );
    },
    getGrowerDiaries: function(data){
      if(!data || !data.id) return {}; 
      return this.get({
        page: 1,
        growername: data.name,
        diaries_count: data.cnt_reports
      });
    },
    getGrowerActivity: function(data){ 
      if(!data || !data.id) return {};
      return this.get({
        page: 1,
        growername: data.name
      });
    },
    getGrowerReviews: function(data){
      if(!data || !data.id) return {}; 
      return this.get({
        page: 1,
        growername: data.name
      });
    },
    getGrowerFollowing: function(data){ 
      if(!data || !data.id) return {};
      return this.get({
        page: 1,
        growername: data.name
      });
    },
    getGrowerFollowers: function(data){ 
      if(!data || !data.id) return {};
      return this.get({
        page: 1,
        growername: data.name
      });
    },
    getGrowersSettings: function(){ 
      return this.get({
      });
    },
    
    // grow-questions
    getGrowQuestions: function(data){ 
      return this.get({
        page: 1,
        categoriesname: data || ''
      });
    },
    getGrowQuestionsEdit: function(){ 
      return this.get({
      });
    },
    getGrowQuestion: function(data){
      // console.log('getGrowQuestion', data);
      
      if(!data?.id) 
        return {};

      var categoriesname = [];
      
      if(data.items_problem_symptom){
        for(var l of data.items_problem_symptom) {
          if(l.type && l.id){
            categoriesname.push(this.t('universal_plant_symptoms_type_' + l.type) + '. ' + this.t('universal_plant_symptom_' + l.id))
          }
        }
      }
      return this.get({
        user: data.item_user ? data.item_user.name : '',
        categoriesname: data.title ? data.title : categoriesname.join(', '),
        filtercategoriesname: categoriesname ? categoriesname.join(', ') : '',
        page: 1,
      });
    },

    // feed
    getFeed: function(){ 
      return this.get({
        page: 1
      });
    },

    // explore
    getExplore: function(data){
      // console.log('head getExplore');
      // console.log(data);

      return this.get({
        page: 1,
        filtername: data || ''
      });
    },

    // brandseed
    getBrandSeedList: function(){ 
      return this.get({
        page: 1
      });
    }, 
    getBrandSeed: function(data){ 
      if(!data || !data.id) return {};
      return this.get(
        {
          brandname: data.name,
          page: 1
        }
      );
    },
    getBrandSeedInfo: function(data){ 
      
      if(!data?.id) 
        return {};

      var reviews = [];
      if(data.items_review && data.items_review.length){
        for(var r of data.items_review){
          reviews.push(
            {
              "@type": "Review",
              "name": r.item_product?.name||data?.name,
              "datePublished": r.add_date?.substring(0, 10),
              "reviewBody": r.text,
              "author": {
                "@type": "Person",
                "name": r.item_user.name
              },
              "reviewRating": {
                "@type": "Rating",
                "ratingValue": r.n_general || 1,
                "bestRating": "10",
                "worstRating": "0",
              },
              "itemReviewed": {
                "@type": "Organization",
                "name": data.name,
                "url": this.getHost() + "/seedbank/" + data.section,
                "image": data.logo_s
              }
            }
          );
        }
      }
      return this.get(
        {
          brandname: data.name,
          page: 1
        },
        '',
        {
          "@context": "https://schema.org",
          "@type": "Brand",
          "name": data.name,
          "url": this.getHost() + "/seedbank/" + data.section,
          "image": data.logo_s,
          "description": data.description?.replace(/<\/?[^>]+>/gi, ''),
          "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": data.item_stat?.avg_rate,
            "reviewCount": data.item_stat?.cnt_reviews,
            "bestRating": "10",
            "worstRating": "0",
            "itemReviewed": {
              "@type": "Organization",
              "name": data.name,
              "url": this.getHost() + "/seedbank/" + data.section,
              "image": data.logo_s
            },
          },
          "review": reviews
        }
      );
    },

    // brandnutrient
    getBrandNutrientList: function(){ 
      return this.get({
        page: 1
      });
    },
    getBrandNutrient: function(data){ 


      // console.log('getBrandNutrient');
      // console.log(data);

      if(!data?.id) 
        return {};


      return this.get(
        {
          brandname: data.name,
          page: 1
        }
      );
    },
    getBrandNutrientInfo: function(data){ 
      
      if(!data?.id) 
        return {};

      var reviews = [];
      if(data.items_review && data.items_review.length){
        for(var r of data.items_review){
          reviews.push(
            {
              "@type": "Review",
              "name": r.item_product?.name||data?.name,
              "datePublished": r.add_date?.substring(0, 10),
              "reviewBody": r.text,
              "author": {
                "@type": "Person",
                "name": r.item_user.name
              },
              "reviewRating": {
                "@type": "Rating",
                "ratingValue": r.n_general || 1,
                "bestRating": "10",
                "worstRating": "0",
              },
              "itemReviewed": {
                "@type": "Organization",
                "name": data.name,
                "url": this.getHost() + "/nutrients/" + data.section,
                "image": data.logo_s
              }
            }
          );
        }
      }
      return this.get(
        {
          brandname: data.name,
          page: 1
        },
        '',
        {
          "@context": "https://schema.org",
          "@type": "Brand",
          "name": data.name,
          "url": this.getHost() + "/nutrients/" + data.section,
          "image": data.logo_s,
          "description": data.description?.replace(/<\/?[^>]+>/gi, ''),
          "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": data.item_stat.avg_rate,
            "reviewCount": data.item_stat.cnt_reviews,
            "bestRating": "10",
            "worstRating": "0",
            "itemReviewed": {
              "@type": "Organization",
              "name": data.name,
              "url": this.getHost() + "/nutrients/" + data.section,
              "image": data.logo_s
            },
          },
          "review": reviews
        }
      );
    },

    // brandlamp
    getBrandLampList: function(){ 
      return this.get({
        page: 1
      });
    },
    getBrand: function(data){ 
      if(!data || !data.id) return {};
      return this.get(
        {
          brandname: data.name,
          page: 1
        }
      );
    },
    getProduct: function(data){   
      if(!data?.id) 
        return {};

      return this.get(
        {
          brandname: data.item_brand.name,
          productname: data.name,
          page: 1
        }
      );
    },
    getBrandLamp: function(data){ 
      if(!data || !data.id) return {};
      return this.get(
        {
          brandname: data.name,
          page: 1
        }
      );
    },
    getBrandLampInfo: function(data){ 
      
      if(!data?.id) 
        return {};

      var reviews = [];
      if(data.items_review && data.items_review.length){
        for(var r of data.items_review){
          reviews.push(
            {
              "@type": "Review",
              "name": r.item_product?.name||data?.name,
              "datePublished": r.add_date?.substring(0, 10),
              "reviewBody": r.text,
              "author": {
                "@type": "Person",
                "name": r.item_user.name
              },
              "reviewRating": {
                "@type": "Rating",
                "ratingValue": r.n_general || 1,
                "bestRating": "10",
                "worstRating": "0",
              },
              "itemReviewed": {
                "@type": "Organization",
                "name": data.name,
                "url": this.getHost() + "/grow-lights/" + data.section,
                "image": data.logo_s
              }
            }
          );
        }
      }
      return this.get(
        {
          brandname: data.name,
          page: 1
        },
        '',
        {
          "@context": "https://schema.org",
          "@type": "Brand",
          "name": data.name,
          "url": this.getHost() + "/grow-lights/" + data.section,
          "image": data.logo_s,
          "description": data.description?.replace(/<\/?[^>]+>/gi, ''),
          "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": data.item_stat.avg_rate,
            "reviewCount": data.item_stat.cnt_reviews,
            "bestRating": "10",
            "worstRating": "0",
            "itemReviewed": {
              "@type": "Organization",
              "name": data.name,
              "url": this.getHost() + "/grow-lights/" + data.section,
              "image": data.logo_s
            },
          },
          "review": reviews
        }
      );
    },

    // brandtent
    getBrandTentList: function(){ 
      return this.get({
        page: 1
      });
    },
    getBrandTent: function(data){ 
      if(!data || !data.id) return {};
      return this.get(
        {
          brandname: data.name,
          page: 1
        }
      );
    },
    getBrandTentInfo: function(data){ 
      
      if(!data?.id) 
        return {};

      var reviews = [];
      if(data.items_review && data.items_review.length){
        for(var r of data.items_review){
          reviews.push(
            {
              "@type": "Review",
              "name": r.item_product?.name||data?.name,
              "datePublished": r.add_date?.substring(0, 10),
              "reviewBody": r.text,
              "author": {
                "@type": "Person",
                "name": r.item_user?.name
              },
              "reviewRating": {
                "@type": "Rating",
                "ratingValue": r.n_general || 1,
                "bestRating": "10",
                "worstRating": "0",
              },
              "itemReviewed": {
                "@type": "Organization",
                "name": data?.name,
                "url": this.getHost() + "/grow-tents/" + data.section,
                "image": data.logo_s
              }
            }
          );
        }
      }
      return this.get(
        {
          brandname: data.name,
          page: 1
        },
        '',
        {
          "@context": "https://schema.org",
          "@type": "Brand",
          "name": data.name,
          "url": this.getHost() + "/grow-tents/" + data.section,
          "image": data.logo_s,
          "description": data?.description?.replace(/<\/?[^>]+>/gi, ''),
          "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": data.item_stat?.avg_rate,
            "reviewCount": data.item_stat?.cnt_reviews,
            "bestRating": "10",
            "worstRating": "0",
            "itemReviewed": {
              "@type": "Organization",
              "name": data.name,
              "url": this.getHost() + "/grow-tents/" + data.section,
              "image": data.logo_s
            },
          },
          "review": reviews
        }
      );
    },

    // seed
    getSeed: function(data){ 

      if(!data || !data.id) return {};
      
      return this.get(
        {
          brandname: data.item_brand.name,
          strainname: data.name,
          page: 1
        }
      );
    },
    getSeedInfo: function(data){ 
      
      if(!data?.id) 
        return {};

      var reviews = [];
      if(data.items_review?.length){
        for(var r of data.items_review){
          reviews.push(
            {
              "@type": "Review",
              "name": r.item_product?.name||data?.name,
              "datePublished": r.add_date?.substring(0, 10),
              "reviewBody": r.text,
              "author": {
                "@type": "Person",
                "name": r.item_user?.name
              },
              "reviewRating": {
                "@type": "Rating",
                "ratingValue": r.n_general || 1,
                "bestRating": "10",
                "worstRating": "0",
              }
            }
          );
        }
      }
      return this.get(
        {
          brandname: data?.item_brand?.name,
          strainname: data?.name,
          page: 1
        },
        '',
        {
          "@context": "https://schema.org",
          "@type": "Product",
          "name": data?.name,
          "sku": data?.id,
          "mpn": data?.id,
          "url": this.getHost() + data.item_brand?.link + '/' + data.section,
          "image": data.avatar_small,
          "description":  data?.description?.replace(/<\/?[^>]+>/gi, ''),
          "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": data.item_stat?.avg_rate,
            "reviewCount": data.item_stat?.cnt_reviews,
            "bestRating": "10",
            "worstRating": "0"
          },
          "review": reviews
        }
      );
    },
    // strain
    getStrain: function(data){ 

      if(!data || !data.id) return {};
      
      return this.get(
        {
          strainname: data.name,
          page: 1
        }
      );
    },
    getStrainInfo: function(data){ 
      
      if(!data?.id) 
        return {};

      var reviews = [];
      if(data.items_review && data.items_review.length){
        for(var r of data.items_review){
          reviews.push(
            {
              "@type": "Review",
              "name": r.item_product?.name||data?.name,
              "datePublished": r.add_date?.substring(0, 10),
              "reviewBody": r.text,
              "author": {
                "@type": "Person",
                "name": r.item_user?.name
              },
              "reviewRating": {
                "@type": "Rating",
                "ratingValue": r.n_general || 1,
                "bestRating": "10",
                "worstRating": "0",
              }
            }
          );
        }
      }
      return this.get(
        {
          strainname: data.name,
          page: 1
        },
        '',
        {
          "@context": "https://schema.org",
          "@type": "Product",
          "name": data.name,
          "sku": data.id,
          "mpn": data.id,
          "url": this.getHost() + data.link,
          "image": data.avatar_small,
          "description": data.description?.replace(/<\/?[^>]+>/gi, ''),
          "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": data.item_stat?.avg_rate,
            "reviewCount": data.item_stat?.cnt_reviews,
            "bestRating": "10",
            "worstRating": "0"
          },
          "review": reviews
        }
      );
    },
    getStrainCategory: function(data){ 

      if(!data || !data.name) return {};
      
      return this.get(
        {
          subcategory: data.name,
          page: 1
        }
      );
    },

    // nutrient
    getNutrient: function(data){ 
      
      if(!data?.id) 
        return {};
      
      return this.get(
        {
          brandname: data.item_brand.name,
          productname: data.name,
          page: 1
        }
      );
    },
    getNutrientInfo: function(data){ 
      
      if(!data?.id) 
        return {};

      var reviews = [];
      if(data.items_review && data.items_review.length){
        for(var r of data.items_review){
          reviews.push(
            {
              "@type": "Review",
              "name": r.item_product?.name||data?.name,
              "datePublished": r.add_date?.substring(0, 10),
              "reviewBody": r.text,
              "author": {
                "@type": "Person",
                "name": r.item_user.name
              },
              "reviewRating": {
                "@type": "Rating",
                "ratingValue": r.n_general || 1,
                "bestRating": "10",
                "worstRating": "0",
              }
            }
          );
        }
      }
      return this.get(
        {
          brandname: data.item_brand.name,
          productname: data.name,
          page: 1
        },
        '',
        {
          "@context": "https://schema.org",
          "@type": "Product",
          "name": data.name,
          "sku": data.id,
          "mpn": data.id,
          "url": this.getHost() + data.item_brand.link + '/' + data.section,
          "image": data.avatar_small,
          "description": data.description?.replace(/<\/?[^>]+>/gi, ''),
          "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": data.item_stat.avg_rate,
            "reviewCount": data.item_stat.cnt_reviews,
            "bestRating": "10",
            "worstRating": "0"
          },
          "review": reviews
        }
      );
    },
    
    // lamp
    getLamp: function(data){ 
      
      if(!data?.id) 
        return {};

      return this.get(
        {
          brandname: data.item_brand.name,
          productname: data.name,
          page: 1
        }
      );
    },
    getLampInfo: function(data){ 
      
      if(!data?.id) 
        return {};

      var reviews = [];
      if(data.items_review && data.items_review.length){
        for(var r of data.items_review){
          reviews.push(
            {
              "@type": "Review",
              "name": r.item_product?.name||data?.name,
              "datePublished": r.add_date?.substring(0, 10),
              "reviewBody": r.text,
              "author": {
                "@type": "Person",
                "name": r.item_user.name
              },
              "reviewRating": {
                "@type": "Rating",
                "ratingValue": r.n_general || 1,
                "bestRating": "10",
                "worstRating": "0",
              }
            }
          );
        }
      }
      return this.get(
        {
          brandname: data.item_brand.name,
          productname: data.name,
          page: 1
        },
        '',
        {
          "@context": "https://schema.org",
          "@type": "Product",
          "name": data.name,
          "sku": data.id,
          "mpn": data.id,
          "url": this.getHost() + data.item_brand.link + '/' + data.section,
          "image": data.avatar_small,
          "description": data.description?.replace(/<\/?[^>]+>/gi, ''),
          "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": data.item_stat.avg_rate,
            "reviewCount": data.item_stat.cnt_reviews,
            "bestRating": "10",
            "worstRating": "0"
          },
          "review": reviews
        }
      );
    },
    
    // tent
    getTent: function(data){ 
      
      if(!data?.id) 
        return {};

      return this.get(
        {
          brandname: data.item_brand.name,
          productname: data.name,
          page: 1
        }
      );
    },
    getTentInfo: function(data){ 
      
      if(!data?.id) 
        return {};

      var reviews = [];
      if(data.items_review && data.items_review.length){
        for(var r of data.items_review){
          reviews.push(
            {
              "@type": "Review",
              "name": r.item_product?.name||data?.name,
              "datePublished": r.add_date?.substring(0, 10),
              "reviewBody": r.text,
              "author": {
                "@type": "Person",
                "name": r.item_user.name
              },
              "reviewRating": {
                "@type": "Rating",
                "ratingValue": r.n_general || 1,
                "bestRating": "10",
                "worstRating": "0",
              }
            }
          );
        }
      }
      return this.get(
        {
          brandname: data.item_brand.name,
          productname: data.name,
          page: 1
        },
        '',
        {
          "@context": "https://schema.org",
          "@type": "Product",
          "name": data.name,
          "sku": data.id,
          "mpn": data.avatar_small,
          "url": this.getHost() + data.item_brand.link + '/' + data.section,
          "image": data.avatar_small,
          "description": data.description?.replace(/<\/?[^>]+>/gi, ''),
          "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": data.item_stat.avg_rate,
            "reviewCount": data.item_stat.cnt_reviews,
            "bestRating": "10",
            "worstRating": "0"
          },
          "review": reviews
        }
      );
    },

    // brand-manager
    getBrandManager: function(){ 
      return this.get({
        page: 1
      });
    },

    // static page
    getStaticPage: function(){ 
      return this.get({
      });
    },

    // notifications
    getNotifications: function(){ 
      return this.get({
        page: 1
      });
    },

    // login
    getLogin: function(){ 
      return this.get({
      });
    },

    getSignin: function(){ 
      return this.get({
      });
    },

    // Giveaway
    getGiveaways: function(){ 
      return this.get({
        page: 1
      });
    },
    getGiveawayItem: function(data){ 
      
      if(!data?.id) 
        return {};
      
      return this.get(
        {
          giveawayname: data.name,
          page: 1
        }
      );
    },
    // strains
    getSeeds: function(){ 
      return this.get({
        page: 1
      });
    },
    getStrains: function(){ 
      return this.get({
        page: 1
      });
    },
    
  }

  
  return {
    provide: {
      head: head,
    },
  };

})