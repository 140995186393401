

export default defineNuxtPlugin(nuxtApp =>{  

  const scrollto = (opt) => {


    const options = {
      el: opt.el.value ?? (opt.el ?? null), 
      onScrolled: opt.onScrolled ?? function() {},
      offset: opt.offset ?? 0,
    };

    // console.log(options.el);
    // console.log(options.el.value);

    if(import.meta.client && typeof options.el !== 'undefined' && options.el !== null) {
        
      const elementTop = options.el.getBoundingClientRect().top + window.pageYOffset;

      window.scrollTo({
        top: elementTop - options.offset, 
        behavior: 'smooth'
      });

    }

  }


   
  return {
    provide: {
      scrollto: scrollto,
    },
  };

})