<template>
  
  <div 
    class="bd prose"
    :theme="useNavbarTheme().value"
    >

    <NuxtLink to="/" class="logo"> 
      <img
        class="lg"
        src="/logo/logo_ver.svg"
        alt="Growdiaries"
      />
    </NuxtLink>
    
    <template v-if="error?.statusCode === 404">
      <h1>404!</h1>
      <p>Sorry, that page doesn't exist.</p>
    </template>

    <template v-else>
      <h1>Ooops!</h1>
      <p>It looks like something broke.</p>
      <p>Sorry about that.</p>
    </template>

    <div class="om">
      <strong>{{ error?.message }}</strong>
    </div>

    <p>
      Go back to your 
      <NuxtLink to="/">
        Home page
      </NuxtLink>
    </p>

  </div>

</template>

<script setup>



const { $err } = useNuxtApp();

definePageMeta({
  layout: 'default'
})

const error = useError();
 

onMounted(() => {
  $err.send(error.value, 'page-error')
})


</script>

<style scoped>
 
 .prose{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  font-family: var(--gd-text-font-family);
  position: absolute;
 }
 .prose h1{
  font-size: 3rem;
 }
 .prose p{}
 .prose a{
  text-decoration: underline;
 }
 

 .logo{
  width: 100%;
  max-width: 150px;
  margin-bottom: 6rem;
 }
 .logo .lg{
  
}

.om{
  display: none;
}
</style>