// import { ref } from "vue";

// const debugList = ref([])

// export const useDebugList = () => {

//   const add = (row) => {    
//     debugList.value.push(row)
//   }
 
//   return {debugList, add};
// }


export const useDebugListFor = () => useState('useDebugListFor', () => [])