
export default defineNuxtPlugin(nuxtApp => {  

  var likes = {
   
    toggle: function(type, id, current) {
      
      console.log('current');
      console.log(current);

      if(!current)
        return this.add(type, id);
      else 
        return this.delete(type, id);
    },
    add: function(type, id) {

      return nuxtApp.$api.postLike(type, id) 
      // .then((res) => {                 
      //   console.log('liked');       
      //   console.log(res);                 
      // })
      // .catch(function (error) { 
      //   console.log('like error');       
      //   console.log(error);                 
      // });  

      // return retVal;
    },
    delete: function(type, id) {

      return nuxtApp.$api.deleteLike(type, id) 
      // .then((res) => {                 
      //   console.log('unliked');       
      //   console.log(res);                 
      // })
      // .catch(function (error) { 
      //   console.log('unlike error');       
      //   console.log(error);                 
      // });  

      //return retVal;
    }
  } 
  

  return {
    provide: {
      likes: likes,
    },
  };

})