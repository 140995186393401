

export default defineNuxtPlugin(nuxtApp => { 

  var zoomEnabled = true;

  var currentInput = null;

  if (import.meta.client) {
    const updateViewport = (content) => {
      document.querySelector('meta[name=viewport]').setAttribute('content', content);
    };


    const disableZoom = () => {
      updateViewport('width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no');
    };


    const enableZoom = () => {
      updateViewport('width=device-width, initial-scale=1.0, maximum-scale=5.0, user-scalable=yes');
    };

    document.addEventListener('blur', (event) => {
      if (currentInput == event.target && event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA') {
        console.log('blur');
        enableZoom();
        currentInput = null;
      }

    }, true);

    document.addEventListener('touchstart', (event) => {
      if (event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA') {
        console.log('touchstart: disable zoom');
        disableZoom();
        currentInput = event.target;
      }
    }, false);

    document.addEventListener('focus', (event) => {
      if (event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA') {
        console.log('focus: disable zoom');
        disableZoom();
        currentInput = event.target;
      }
    }, true);
 
  }

  
  
 
})