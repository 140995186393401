import { createApp } from 'vue'
import AdvBoxComponent from '@/components/adv/Box.vue'

export const advBox = {
   
  list: [],
  prepared: [],
  last_row: 0,
  last_num: 0,
  insert_count: 0,
  elements: [],
  cookieNameViewedPackages: 'ab_v_packages',
  cookieNameViewedPackagesBanners: 'ab_v_packages_banners',

  getRandomInt: function(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  },
  isMobile: function(){
    return window.innerWidth <= 768;
  },
  viewBox: async function(id){           
    
    // useNuxtApp().$api.postAdvViewBox(id, this.getSection());      
    useAdvStat().value.push({
      id: id,
      type: 'cpm_box',
      section: this.getSection(),
      action: 'view',
    });
    // useNuxtApp().$adv.sendStat();
    this.addImpression(id);
    this.addViewedBanner(id);
    this.addViewedPackage(this.getPackageIdByBannerId(id));
  }, 
  clickBox: async function(id){       
    // useNuxtApp().$api.postAdvClickBox(id, this.getSection());     
    useAdvStat().value.push({
      id: id,
      type: 'cpm_box',
      section: this.getSection(),
      action: 'click',
    });
    // useNuxtApp().$adv.sendStat();
    useNuxtApp().$ga.clickBanner('box'); 
  }, 
  addImpression: function(id){
    
    if(!this.list.length)
      return false;

    var pack = this.list.find((item) => {
      return item.banners?.length && item.banners.find((itemBanner) => {
        return itemBanner.id == id;
      });
    });

    if(!pack)
      return false;

    pack.sys_impression++;

    var itemBanner = pack.banners.find((itemBanner) => {
      return itemBanner.id == id;
    });

    if(!itemBanner)
      return false;
    
    itemBanner.sys_impression++;

    // console.log('addImpression cpm_box:', itemBanner);
  },
  getPackagesIds: function(packages){
    if(!packages?.length)
      return [];

    var ids = [];
    for(var itemPackage of packages){
      ids.push(itemPackage.id);
    }
    return ids;
  },
  getPackageIdByBannerId: function(id){
    var packages = this.list;
    for(var itemPackage of packages){
      for(var itemBanner of itemPackage.banners){
        if(itemBanner.id == id)
          return itemPackage.id;
      }
    }
    return null;
  },
  getSection: function(){              
    // var path = router.currentRoute.value.matched[0].path;
    const router = useRouter(); 
    var path = router.currentRoute.value.fullPath;
    path = path.split('/');
    var section = path[1] ? path[1] : 'index';
    return section;
  },
  isIgnorePages: function(){   
    const router = useRouter(); 
    var path = router.currentRoute.value.fullPath;
    var ignore = [
      'diaries/[\\w-]+/edit/week',
      'grower/[\\w-]+/settings',
      'diaries/edit',
      'brands-manager',
    ];
    ignore = ignore.map((item) => {
      return new RegExp(item);
    });
    for(var item of ignore){
      if(item.test(path))
        return true;
    }
    return false;
  },
  isIgnoreSection: function(){   
    const router = useRouter(); 
    var path = router.currentRoute.value.fullPath;
    const { advList } = useAdvList();
    if(!advList.value?.ignore_sections)
      return false;
    // console.log('advList.value.ignore_sections');
    // console.log(path);
    var ignore_sections = advList.value.ignore_sections;
    for(var ignore_section of ignore_sections){
      if(path.indexOf(ignore_section) >= 0){
        return true;
      }
    }
    return false;
    
  },
  clearBox: function(){
    for(var el of this.elements){
      el.remove();
    }
    
    this.last_row = 0;
    this.last_num = 0;
    this.insert_count = 0;
    this.elements = [];
    
  },
  isReadyBoxApply: function(){
    // console.log('document.querySelector(.report_boxs)');
    // console.log(document.querySelector('.index_page').innerHTML);
    // console.log(document.querySelector('.report_boxs'));
    if(document.querySelector('.report_boxs') && document.querySelector('.report_box_item'))
      return true
    return false
  },     
  countBoxInRow: function(){      
    let container_width = document.querySelector('.report_boxs').offsetWidth;
    let box_width = document.querySelector('.report_box_item').offsetWidth;
    var box_in_row = Math.round(container_width / box_width);
    return box_in_row;
  },
  setLastNum: function(num){
    this.last_num = num;
  },
  setLastRow: function(num){
    this.last_row = num;
  },
  setList: function(){
    const { advList } = useAdvList();
    if(!advList.value?.items_box)
      return [];
    this.list = advList.value.items_box;
  },

  getViewedPackages: function(){
    var viewed = useCookie(this.cookieNameViewedPackages,{maxAge: 60 * 60 * 24 * 31 * 6}).value ?? '';
    viewed += '';
    if(!viewed)
      return [];
    if(viewed.indexOf(',') < 0)
      var ret = [viewed];
    else
      var ret = viewed.split(',');

    return ret.map((item) => {
      return Number(item);
    });
  },
  setViewedPackages: function(list){
    if(!list?.length)
      return;
    useCookie(this.cookieNameViewedPackages,{maxAge: 60 * 60 * 24 * 31 * 6}).value = list.join(',');
  },

  getViewedPackagesBanners: function(){
    var viewed = useCookie(this.cookieNameViewedPackagesBanners,{maxAge: 60 * 60 * 24 * 31 * 6}).value ?? '';
    viewed += '';
    if(!viewed)
      return [];
    if(viewed.indexOf(',') < 0)
      var ret = [viewed];
    else
      var ret = viewed.split(',');

    return ret.map((item) => {
      return Number(item);
    });
  },
  setViewedPackagesBanners: function(list){
    if(!list?.length)
      return;
    useCookie(this.cookieNameViewedPackagesBanners,{maxAge: 60 * 60 * 24 * 31 * 6}).value = list.join(',');
  },
  removeViewedPackage: function(id, list){
    return list.filter((item) => {
      return item != id;
    })
  },
  addViewedPackage: function(id){
    if(!id) return;
    id = Number(id);
    var viewed = this.getViewedPackages();
    // console.log('getViewedPackages', viewed);
    viewed = this.removeViewedPackage(id, viewed);
    // viewed.unshift(id);
    viewed.push(id);
    // console.log('getViewedPackages', viewed);
    this.setViewedPackages(viewed);
  },
  addViewedBanner: function(id){
    if(!id) return;
    id = Number(id);
    var viewed = this.getViewedPackagesBanners();
    // console.log('getViewedPackages', viewed);
    viewed = this.removeViewedPackage(id, viewed);
    // viewed.unshift(id);
    viewed.push(id);
    // console.log('getViewedPackages', viewed);
    this.setViewedPackagesBanners(viewed);
  },
  sortPackageBannersByViews: function(banners){
    var viewed = this.getViewedPackagesBanners();
    if(!viewed.length)
      return banners;
    
    return banners.sort((a, b) => {
      return viewed.indexOf(a.id) - viewed.indexOf(b.id);
    })
  },
  sortPackageByViews: function(packages){
    var viewed = this.getViewedPackages();
    if(!viewed.length)
      return packages;
    
    return packages.sort((a, b) => {
      // return  viewed.indexOf(a.id) - viewed.indexOf(b.id);
      // console.log('sort');
      // console.log(a.id);
      // console.log(viewed.indexOf(a.id));
      return viewed.indexOf(a.id) - viewed.indexOf(b.id);
    })
  },
  prepare: function(){
    this.setLastNum(0);
    this.setLastRow(0);
    // this.clearBox();
    this.sortAndFilter();
  },
  flattenBanners: function(packages){

    if(!packages.length)
      return [];

    var forMax = JSON.parse(JSON.stringify(packages));
    var maxCountBannersInPackage = forMax.sort((a, b) => {
      return  b.banners.length - a.banners.length;
    })[0].banners.length;
    
    // console.log('maxCountBannersInPackage');
    // console.log(maxCountBannersInPackage);

    // fille ampty banners
    for(var itemPackage of packages){
      if(itemPackage.banners.length < maxCountBannersInPackage){
        for(var i = itemPackage.banners.length; i < maxCountBannersInPackage; i++){
          itemPackage.banners = [...itemPackage.banners, ...itemPackage.banners];
        }
        itemPackage.banners = itemPackage.banners.slice(0, maxCountBannersInPackage);
      }
    }


    // console.log('packages filled');
    // console.log(packages);
    

    var banners = []; 

    for(var i = 0; i < maxCountBannersInPackage; i++){
      for(var itemPackage of packages){
        if(itemPackage.banners[i]){
          banners.push(itemPackage.banners[i]);
        }
      }
    }

    // console.log('banners');
    // console.log(banners);



    return banners;
  },
  sortAndFilter: function(){

    var packages = JSON.parse(JSON.stringify(this.list));

    if(this.getSection() == 'index'){
      
      var banners = [];
      var boosted_banner_1 = null;
      var boosted_banner_2 = null;
      
      var boostedPackages = packages.filter((itemPackage) => {
        return itemPackage.boosted;
      });


      if(boostedPackages.length){

        boostedPackages.sort((a, b) => {
          return a.sys_impression - b.sys_impression;
        });
  
        boostedPackages = this.sortPackageByViews(boostedPackages);        

        var boostedPackage = boostedPackages[0];

        if(boostedPackages.length && boostedPackage.banners.length){

          let banners_package_0 = boostedPackages[0].banners;
          if(banners_package_0.length > 1){

            banners_package_0 = banners_package_0.sort((a, b) => {
              return a.sys_impression - b.sys_impression;
            })
            banners_package_0 = this.sortPackageBannersByViews(banners_package_0);
            banners.push(banners_package_0[0]);

          }


          if(boostedPackages.length && boostedPackage.banners.length > 1){
            boosted_banner_2 = boostedPackage.banners.sort((a, b) => {
              return a.sys_impression - b.sys_impression;
            })[1];
          }else{
            boosted_banner_1 = boostedPackage.banners.sort((a, b) => {
              return a.sys_impression - b.sys_impression;
            })[0];
            // banners.push(boostedPackages[0].banners[0]);
          }
        }
      }


      var otherPackages = JSON.parse(JSON.stringify(packages));

      // console.log('otherPackages');
      // console.log(JSON.parse(JSON.stringify(otherPackages)));

      // var boostedPackagesIds = this.getPackagesIds(boostedPackages);
      
      // boostedPackages = boostedPackages.filter((itemPackage) => {
      //   return itemPackage.id != boostedPackage.id;
      // });

      // console.log('otherPackages');
      // console.log(JSON.parse(JSON.stringify(otherPackages)));

      otherPackages.sort((a, b) => {
        // return  b.sys_impression - a.sys_impression;
        return (a.sys_impression / a.boost) - (b.sys_impression / b.boost);
      });


      otherPackages = this.sortPackageByViews(otherPackages);


      // console.log('boostedPackages');
      // console.log(boostedPackages);
      // console.log('otherPackages');
      // console.log(otherPackages);

      var boostedBanners = this.flattenBanners(boostedPackages);
      var otherBanners = this.flattenBanners(otherPackages);

      if(boosted_banner_1){
        banners.push(boosted_banner_1);
      }

      banners = [...banners, ...otherBanners];

      if(boosted_banner_2){
        banners.push(boosted_banner_2);
      }

      banners = [...banners, ...boostedBanners];

      // console.log('banners');
      // console.log(banners);

    }else{

      // console.log('packages before');
      // console.log(JSON.parse(JSON.stringify(packages)));

      packages.sort((a, b) => {
        // console.log('sort' + a.id + ' ' + b.id);
        // console.log('boost' + a.boosted + ' ' + b.boosted);
        // console.log('result' + (a.sys_impression / (a.boosted ? 2 : 1)) + ' ' + (b.sys_impression / (b.boosted ? 2 : 1)));
        return (a.sys_impression / a.boost) - (b.sys_impression / b.boost);
      });


      packages = this.sortPackageByViews(packages);

      // console.log('packages after');
      // console.log(JSON.parse(JSON.stringify(packages)));


      var banners = this.flattenBanners(packages);

    }

    this.prepared = banners;
    return banners;
    
  }, 
  getNextBox: function(){


    if(this.last_num > this.prepared.length - 1){
      this.last_num = 0;
    }

    var ret = this.prepared[this.last_num];

    this.last_num++;
    this.insert_count++;

    return ret;

  }, 
  applyBox: function(){
    if(this.isIgnoreSection())
      return false;
    if(this.isIgnorePages())
      return false;

      // console.log('apply box');
      
        
    if(this.getSection() == 'index')
      this.applyBoxFeed()
    else
      this.applyBoxList()
  },
  applyBoxList: function(){

    // if(this.getSection() == 'explore' && this.isMobile()){
    //   return false;
    // }

    if(!this.isReadyBoxApply())
      return false

    var count_in_row = this.countBoxInRow();
    var count_rep = document.querySelectorAll('.report_boxs .report_box_item').length;
    var count_box = document.querySelectorAll('.report_boxs .cpm_box').length;


    // console.log('applyBoxList');
    // console.log('count_rep', count_rep);
    // console.log('count_box', count_box);

    if(!count_rep || !count_in_row) 
      return false; 


      count_rep += count_box;

    // console.log('adv box start list running found container');

    var count_row = Math.ceil(count_rep/count_in_row);

    var start_row = 1;
    var repeat_row = this.isMobile() ? 4 : 3;

    

    for(var i = 0; i < count_row/(repeat_row-1); i++){

      // console.log(count_row);

      if(count_row >= this.last_row+repeat_row){
        var last_row = this.last_row + (this.last_row ? repeat_row : start_row);
        // console.log('last_row', last_row);
        var num_start = (last_row * count_in_row)-count_box-1;
        var num_rosition =  num_start + (count_in_row > 2 ? (this.getRandomInt(Math.round(count_in_row/2),count_in_row)-1) : 1);

        
        
        
        if(document.querySelectorAll('.report_boxs .report_box_item')[num_rosition]){          
          
          var data_box = this.getNextBox(); 
        
          // let toastComp = nuxtApp.vueApp.extend(AdvBox)
          // let html = new toastComp({
          //   propsData: {
          //     data: data_box,
          //     adv: this,
          //   }
          // }).$mount();

          // this.elements.push(html.$el);
          
          
          // document.querySelectorAll('.report_boxs .report_box_item')[num_rosition].after(html.$el);


           
          let mountNode = document.createElement('div')
          mountNode.className = 'cpm_box' 

          // console.log('data_box');
          // console.log(data_box);

          var app = createApp(AdvBoxComponent,{
            data: data_box,
            adv: this,
          })
          // app.use(VueObserveVisibility);
          app.mount(mountNode) 


          // console.log(mountNode);
          
          document.querySelectorAll('.report_boxs .report_box_item')[num_rosition].after(mountNode); 


          this.last_row = last_row;
          count_box++;






        }
      
      }
    }


  },
  applyBoxFeed: function(){

    if(!this.isReadyBoxApply())
      return false
    
    // console.log('apply box feed');

    // console.log('applyBox');

    // this.last_row = 0;
      
    // console.log('adv box start feed running');

    var count_in_row = this.countBoxInRow();
    var count_row = document.querySelectorAll('.report_boxs').length;

    // console.log('document.querySelectorAll(.report_boxs)', document.querySelectorAll('.report_boxs'));
    // console.log('applyboxfeed');
    // console.log(count_in_row);
    // console.log(count_row);

    if(!count_row || !count_in_row) 
      return false;

    // console.log('apply box count_row');

    var start_row = 0;
    var repeat_row = this.isMobile() ? 4 : 3;

    this.last_row = this.last_row ? this.last_row : 0;
    
    // console.log('this.last_row', this.last_row);
    // console.log('count_row', count_row);
    // console.log('repeat_row', repeat_row);

    for(var i = 0; i < count_row/(repeat_row-1); i++){        

      if(count_row >= this.last_row){


        var last_row = this.last_row;

        // console.log('last_row');
        // console.log(last_row);

        if(!last_row){
          var num_start = count_in_row > 2 ? (1 * count_in_row)-1 : 0;
          var num_rosition =  num_start + (count_in_row > 2 ? (this.getRandomInt(Math.round(count_in_row/2),count_in_row-2)-1) : 0);
        }else{
          var num_rosition = (count_in_row > 2 ? (this.getRandomInt(Math.round(count_in_row/2),count_in_row-2)-1) : 0);
        }
        

        if(
          document.querySelectorAll('.report_boxs')[last_row] 
          && 
          document.querySelectorAll('.report_boxs')[last_row].querySelectorAll('.report_box_item')[num_rosition]
        ){          

          
          var data_box = this.getNextBox(); 
         

          let mountNode = document.createElement('div')
          mountNode.className = 'cpm_box' 

          var app = createApp(AdvBoxComponent,{
            data: data_box,
            adv: this,
          })
          // app.use(VueObserveVisibility);
          app.mount(mountNode) 

          // console.log('mountNode');
          // console.log(num_rosition);
          // console.log(document.querySelectorAll('.report_boxs')[last_row].querySelectorAll('.cpm_box').length);

          // if(document.querySelectorAll('.report_boxs')[last_row].querySelectorAll('.cpm_box').length >0){
          //   return false;
          // }

          // if(document.querySelectorAll('.report_boxs')[last_row].querySelectorAll('.report_box_item')

          
          document.querySelectorAll('.report_boxs .report_box_item')[num_rosition].after(mountNode); 

          if(document.querySelectorAll('.report_boxs')[last_row].querySelectorAll('.report_box_item')[num_rosition]){
            document.querySelectorAll('.report_boxs')[last_row].querySelectorAll('.report_box_item')[num_rosition].after(mountNode);
          }else{
            document.querySelectorAll('.report_boxs')[last_row].append(mountNode);
          }
          this.last_row = this.last_row ? last_row + repeat_row : 3;
          

        }




      }
    }
    
  }, 
}