/*
Need for smooth scroll 
and prevent scroll to top when change route
*/
import type { RouterOptions } from '@nuxt/schema'
export default <RouterOptions> { 
  scrollBehavior(to, from, savedPosition) {

    var toPaths = to.path.split('/')
    var fromPaths = from.path.split('/')

    // console.log('toPaths');
    // console.log(toPaths);
    // console.log(fromPaths);

    var scroll = import.meta.client ? window.scrollY : 0;
   
    var top = 0;
    if(fromPaths[1] && (toPaths[1] != fromPaths[1] || toPaths[2] != fromPaths[2])){
      top = 0;
    }else{
      top = scroll;
    }

    return { 
      top: top,
      // behavior: 'smooth',
    }

  }
}