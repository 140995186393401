import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_yfWm7jX06p from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_Jozdw60ZsE from "/app/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import plugin_client_UYiXMU8ZyN from "/app/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import _00_auth_t8DHSJOtQv from "/app/plugins/00.auth.ts";
import _00_error_6idUnpzF5a from "/app/plugins/00.error.ts";
import _01_api_GIiciWaEWX from "/app/plugins/01.api.ts";
import _08_gtag_eGnHHjNeL9 from "/app/plugins/08.gtag.ts";
import _09_adv_HFdllhbrxF from "/app/plugins/09.adv.ts";
import _10_progress_bar_content_NwbIieQ1O8 from "/app/plugins/10.progress-bar-content.ts";
import bfcache_NKWEmLrDdJ from "/app/plugins/bfcache.ts";
import complains_v9wbDBuViZ from "/app/plugins/complains.ts";
import constants_2m2NibMVoo from "/app/plugins/constants.ts";
import convert_nxAZzSHR2J from "/app/plugins/convert.ts";
import debug_ZiGB8yHUre from "/app/plugins/debug.ts";
import directive_click_outside_wTJG2qc45m from "/app/plugins/directive-click-outside.ts";
import disable_zoom_yN6wOTBoh9 from "/app/plugins/disable-zoom.ts";
import donut_pgMIgwHNgf from "/app/plugins/donut.ts";
import emoji_N2luKLieB8 from "/app/plugins/emoji.ts";
import follow_jiBEVjOIMH from "/app/plugins/follow.ts";
import google_recaptcha_rYK9Kziv68 from "/app/plugins/google-recaptcha.ts";
import head_xR53WE0jXG from "/app/plugins/head.ts";
import helper_6DwSy9IstG from "/app/plugins/helper.ts";
import i18n_VfGcjrvSkj from "/app/plugins/i18n.ts";
import likes_jIysdhNAeL from "/app/plugins/likes.ts";
import log_js_error_mRLrExcPRw from "/app/plugins/log-js-error.ts";
import masonry_wall_JM1pmNGn6c from "/app/plugins/masonry-wall.ts";
import observer_j99th6pTGZ from "/app/plugins/observer.ts";
import patcher_dPOuob96GK from "/app/plugins/patcher.ts";
import plurals_yXuARJmZEA from "/app/plugins/plurals.ts";
import popup_MOXMzmn7YL from "/app/plugins/popup.ts";
import presave_sL5EYwqHRw from "/app/plugins/presave.ts";
import rand_Q9bcNVmTXa from "/app/plugins/rand.ts";
import scroll_prevent_vEU1ZkWQ2n from "/app/plugins/scroll-prevent.ts";
import scroll_to_TryEm3rMyC from "/app/plugins/scroll-to.ts";
import security_am6Sh1mD93 from "/app/plugins/security.ts";
import server_secret_Lj6ortOJPJ from "/app/plugins/server-secret.ts";
import spec_chars_tPU7OofsaK from "/app/plugins/spec-chars.ts";
import tags_S52feucwCH from "/app/plugins/tags.ts";
import translate_56zPzW3R38 from "/app/plugins/translate.ts";
import uniq_HlhmaPUx7i from "/app/plugins/uniq.ts";
import user_updates_2HuXKVa2tz from "/app/plugins/user-updates.ts";
import vartpl_lO52QjgNif from "/app/plugins/vartpl.ts";
import vue3_touch_events_3JrctwDJKG from "/app/plugins/vue3-touch-events.ts";
import vuedraggable_p3ImtCUeuI from "/app/plugins/vuedraggable.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  i18n_yfWm7jX06p,
  plugin_Jozdw60ZsE,
  plugin_client_UYiXMU8ZyN,
  _00_auth_t8DHSJOtQv,
  _00_error_6idUnpzF5a,
  _01_api_GIiciWaEWX,
  _08_gtag_eGnHHjNeL9,
  _09_adv_HFdllhbrxF,
  _10_progress_bar_content_NwbIieQ1O8,
  bfcache_NKWEmLrDdJ,
  complains_v9wbDBuViZ,
  constants_2m2NibMVoo,
  convert_nxAZzSHR2J,
  debug_ZiGB8yHUre,
  directive_click_outside_wTJG2qc45m,
  disable_zoom_yN6wOTBoh9,
  donut_pgMIgwHNgf,
  emoji_N2luKLieB8,
  follow_jiBEVjOIMH,
  google_recaptcha_rYK9Kziv68,
  head_xR53WE0jXG,
  helper_6DwSy9IstG,
  i18n_VfGcjrvSkj,
  likes_jIysdhNAeL,
  log_js_error_mRLrExcPRw,
  masonry_wall_JM1pmNGn6c,
  observer_j99th6pTGZ,
  patcher_dPOuob96GK,
  plurals_yXuARJmZEA,
  popup_MOXMzmn7YL,
  presave_sL5EYwqHRw,
  rand_Q9bcNVmTXa,
  scroll_prevent_vEU1ZkWQ2n,
  scroll_to_TryEm3rMyC,
  security_am6Sh1mD93,
  server_secret_Lj6ortOJPJ,
  spec_chars_tPU7OofsaK,
  tags_S52feucwCH,
  translate_56zPzW3R38,
  uniq_HlhmaPUx7i,
  user_updates_2HuXKVa2tz,
  vartpl_lO52QjgNif,
  vue3_touch_events_3JrctwDJKG,
  vuedraggable_p3ImtCUeuI
]