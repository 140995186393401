export default defineNuxtPlugin(nuxtApp => {

const tagsUtil = {
  tagsEquipment: {  
    // all: {
    //   id: 'all',
    //   img: '/images/product-categories/all.svg',
    //   trkey: 'category_all',
    //   group: 'g1',
    //   blocking_by_group: false,
    // },
    tent: {
      id: 'tent',
      img: '/images/product-categories/tent.svg',
      trkey: 'category_grow_tents',
      group: 'g10',
      blocking_by_group: false,
    },
    grow_box: {
      id: 'grow_box',
      img: '/images/product-categories/grow_box.svg',
      trkey: 'category_grow_boxes',
      group: 'g10',
      blocking_by_group: false,
    },
    ventilation_fan: {
      id: 'ventilation_fan',
      img: '/images/product-categories/ventilation_fan.svg',
      trkey: 'category_ventilation_fans',
      group: 'g10',
      blocking_by_group: false,
    },
    air_filter: {
      id: 'air_filter',
      img: '/images/product-categories/air_filter.svg',
      trkey: 'category_air_filter',
      group: 'g10',
      blocking_by_group: false,
    },
    air_conditioner: {
      id: 'air_conditioner',
      img: '/images/product-categories/air_conditioner.svg',
      trkey: 'category_air_conditioners',
      group: 'g10',
      blocking_by_group: false,
    },
    drip_system: {
      id: 'drip_system',
      img: '/images/product-categories/drip_system.svg',
      trkey: 'category_drip_systems',
      group: 'g10',
      blocking_by_group: false,
    },
    hydroponic_system: {
      id: 'hydroponic_system',
      img: '/images/product-categories/hydroponic_system.svg',
      trkey: 'category_hydroponic_systems',
      group: 'g10',
      blocking_by_group: false,
    },
    controller: {
      id: 'controller',
      img: '/images/product-categories/controller.svg',
      trkey: 'category_controllers',
      group: 'g10',
      blocking_by_group: false,
    },
    co2_generator: {
      id: 'co2_generator',
      img: '/images/product-categories/co2_generator.svg',
      trkey: 'category_co2_generators',
      group: 'g10',
      blocking_by_group: false,
    },
  },
  tagsGrowerDiaries: {  
    all: {
      id: 'all',
      trkey: 'universal_category_all',
      group: 'g10'
    }, 
    growing: {
      id: 'growing',
      trkey: 'universal_category_growing',
      group: 'g1'
    },
    harvested: {
      id: 'harvested',
      trkey: 'universal_category_harvested',
      group: 'g1'
    },
    autoflowering: {
      id: 'autoflowering',
      trkey: 'universal_type_seed_auto',
      group: 'g2'
    },
    photoperiod: {
      id: 'photoperiod',
      trkey: 'universal_category_photoperiod',
      group: 'g2'
    },
    indoor: {
      id: 'indoor',
      trkey: 'universal_type_room_indoor',
      group: 'g3'
    },
    outdoor: {
      id: 'outdoor',
      trkey: 'universal_type_room_outdoor',
      group: 'g3'
    },
    fl: {
      id: 'fl',
      trkey: 'universal_type_light_short_fl',
      group: 'g8_1'
    },
    hid: {
      id: 'hid',
      trkey: 'universal_type_light_short_hid',
      group: 'g8_2'
    },
    led: {
      id: 'led',
      trkey: 'universal_type_light_short_led',
      group: 'g8_3'
    }, 
    champions: {
      id: 'champions',
      trkey: 'universal_category_champions',
      group: 'g5'
    },
    video: {
      id: 'video',
      trkey: 'universal_category_video',
      group: 'g9'
    },    
    soil: {
      id:'soil', 
      trkey: 'universal_type_soil_soil', 
      group: 'g5'
    },
    hydro: {
      id:'hydro', 
      trkey: 'universal_type_soil_hydro', 
      group: 'g5'
    },
    soilless: {
      id:'soilless', 
      trkey: 'universal_type_soil_soilless', 
      group: 'g5'
    },
  },
  tagsGiveaway: {  
    all: {
      id: 'all',
      trkey: 'universal_category_all',
      group: 'g10'
    },
    eu: {
      id: 'eu',
      trkey: 'EU',
      group: 'g7'
    },
    us: {
      id: 'us',
      trkey: 'US',
      group: 'g7'
    },
    int: {
      id: 'int',
      trkey: 'INT',
      group: 'g7'
    },
    voting: {
      id: 'voting',
      trkey: 'Voting',
      group: 'g4'
    },
    sponsored: {
      id: 'sponsored',
      trkey: 'Sponsored',
      group: 'g2'
    },
    notsponsored: {
      id: 'notsponsored',
      trkey: 'Not sponsored',
      group: 'g2'
    },
  },

  tagsExplore: {
    all: {
      id: 'all',
      trkey: 'universal_category_all',
      group: 'g10'
    },
    unread: {
      id: 'unread',
      trkey: 'universal_category_unread',
      group: 'g7',
      isAuth: true
    },
    growing: {
      id: 'growing',
      trkey: 'universal_category_growing',
      group: 'g1'
    },
    harvested: {
      id: 'harvested',
      trkey: 'universal_category_harvested',
      group: 'g1'
    },
    autoflowering: {
      id: 'autoflowering',
      trkey: 'universal_type_seed_auto',
      group: 'g2'
    },
    photoperiod: {
      id: 'photoperiod',
      trkey: 'universal_category_photoperiod',
      group: 'g2'
    },
    indoor: {
      id: 'indoor',
      trkey: 'universal_type_room_indoor',
      group: 'g3'
    },
    outdoor: {
      id: 'outdoor',
      trkey: 'universal_type_room_outdoor',
      group: 'g3'
    },
    fl: {
      id: 'fl',
      trkey: 'universal_type_light_short_fl',
      group: 'g8_1'
    },
    hid: {
      id: 'hid',
      trkey: 'universal_type_light_short_hid',
      group: 'g8_2'
    },
    led: {
      id: 'led',
      trkey: 'universal_type_light_short_led',
      group: 'g8_3'
    },
    organic: {
      id: 'organic',
      trkey: 'universal_category_organic',
      group: 'g11'
    },
    cbd: {
      id: 'cbd',
      trkey: 'universal_category_cbd',
      group: 'g12'
    },
    champions: {
      id: 'champions',
      trkey: 'universal_category_champions',
      group: 'g5'
    },
    video: {
      id: 'video',
      trkey: 'universal_category_video',
      group: 'g9'
    },
    questions: {
      id: 'questions',
      trkey: 'universal_category_questions',
      group: 'g13'
    },
    // locale_ru: {
    //   id: 'locale_ru',
    //   trkey: 'universal_category_diaries_ru',
    //   group: 'g15_1'
    // },
    locale_es: {
      id: 'locale_es',
      trkey: 'universal_category_diaries_es',
      group: 'g15_2'
    },
    locale_de: {
      id: 'locale_de',
      trkey: 'universal_category_diaries_de',
      group: 'g15_3'
    },
    lst: {
      id: 'lst',
      trkey: 'universal_type_method_short_lst',
      group: 'g11_2'
    },
    hst: {
      id: 'hst',
      trkey: 'universal_type_method_short_hst',
      group: 'g11_3'
    },
    sog: {
      id: 'sog',
      trkey: 'universal_type_method_short_sog',
      group: 'g11_4'
    },
    scrog: {
      id: 'scrog',
      trkey: 'universal_type_method_short_scrog',
      group: 'g11_5'
    },
    topping: {
      id: 'topping',
      trkey: 'universal_type_method_short_topping',
      group: 'g11_6'
    },
    fim: {
      id: 'fim',
      trkey: 'universal_type_method_short_fiming',
      group: 'g11_7'
    },
    main: {
      id: 'main',
      trkey: 'universal_type_method_short_main_lining',
      group: 'g11_8'
    },
    to1212: {
      id: 'to1212',
      trkey: 'universal_type_method_short_12_12',
      group: 'g11_11'
    },
    defoliation: {
      id: 'defoliation',
      trkey: 'universal_type_method_short_defoliation',
      group: 'g11_9'
    },
    mass_harvests: {
      id: 'mass_harvests',
      trkey: 'universal_category_diaries_mass_harvests',
      group: 'g16'
    },
    
    germ_method: {
      id: 'germ_method',
      trkey: 'universal_category_diaries_germination',
      group: 'w16',
      childs: {
        germ_method_1: {
          id: 'germ_method_1',
          trkey: 'universal_type_germ_method_paper',        
        },
        germ_method_2: {
          id: 'germ_method_2',
          trkey: 'universal_type_germ_method_glass',        
        }, 
        germ_method_4: {
          id: 'germ_method_4',
          trkey: 'universal_type_germ_method_cube',        
        },
        germ_method_5: {
          id: 'germ_method_5',
          trkey: 'universal_type_germ_method_pellet',        
        },
        germ_method_6: {
          id: 'germ_method_6',
          trkey: 'universal_type_germ_method_substrate',        
        },
        germ_method_7: {
          id: 'germ_method_7',
          trkey: 'universal_type_germ_method_other',        
        }
      }
    },
  
    watering: {
      id: 'watering',
      trkey: 'universal_category_diaries_watering',
      group: 'w16',
      childs: {
        watering_1: {
          id: 'watering_1',
          trkey: 'universal_type_watering_manual',        
        },
        watering_2: {
          id: 'watering_2',
          trkey: 'universal_type_watering_drip',        
        },
        watering_3: {
          id: 'watering_3',
          trkey: 'universal_type_watering_hydroponics',    
        },
        watering_4: {
          id: 'watering_4',
          trkey: 'universal_type_watering_aeroponics',        
        }
      }
    },
  
    medium: {
      id: 'medium',
      trkey: 'universal_category_diaries_grow_medium',
      group: 'm16',
      childs: {
        medium_1: {
          id: 'medium_1',
          trkey: 'universal_type_medium_soil',        
        },
        medium_2: {
          id: 'medium_2',
          trkey: 'universal_type_medium_perlite',        
        },
        medium_3: {
          id: 'medium_3',
          trkey: 'universal_type_medium_vermiculite',        
        },
        medium_4: {
          id: 'medium_4',
          trkey: 'universal_type_medium_expanded_clay',        
        },
        medium_5: {
          id: 'medium_5',
          trkey: 'universal_type_medium_coco_coir',        
        },
        medium_6: {
          id: 'medium_6',
          trkey: 'universal_type_medium_mineral_wool',        
        },
        medium_7: {
          id: 'medium_7',
          trkey: 'universal_type_medium_other',        
        }
      }
    }
  },
  tagsBrandSeedDiaries: {  
    all: {
      id: 'all',
      trkey: 'universal_category_all',
      group: 'g10'
    },  
    growing: {
      id:'growing', 
      trkey: 'universal_category_growing', 
      group: 'g2'
    },
    harvested: {
      id:'harvested', 
      trkey: 'universal_category_harvested', 
      group: 'g2'
    },
    autoflowering: {
      id:'autoflowering', 
      trkey: 'universal_type_seed_auto', 
      group: 'g3'
    },
    photoperiod: {
      id:'photoperiod', 
      trkey: 'universal_category_photoperiod', 
      group: 'g3'
    },
    indoor: {
      id:'indoor', 
      trkey: 'universal_type_room_indoor', 
      group: 'g4'
    },
    outdoor: {
      id:'outdoor', 
      trkey: 'universal_type_room_outdoor', 
      group: 'g4'
    },
    soil: {
      id:'soil', 
      trkey: 'universal_type_soil_soil', 
      group: 'g5'
    },
    hydro: {
      id:'hydro', 
      trkey: 'universal_type_soil_hydro', 
      group: 'g5'
    },
    soilless: {
      id:'soilless', 
      trkey: 'universal_type_soil_soilless', 
      group: 'g5'
    },
    fl: {
      id:'fl', 
      trkey: 'universal_type_light_short_fl', 
      group: 'g6'
    },
    hid: {
      id:'hid', 
      trkey: 'universal_type_light_short_hid', 
      group: 'g6'
    },
    led: {
      id:'led', 
      trkey: 'universal_type_light_short_led', 
      group: 'g6'
    },
    champions: {
      id:'champions', 
      trkey: 'universal_category_champions', 
      group: 'g7'
    },
    following: {
      id:'following', 
      trkey: 'universal_category_following', 
      group: 'g8'
    },
    video: {
      id:'video', 
      trkey: 'universal_category_video', 
      group: 'g9'
    },
  
  },
  tagsBrandSeedGallery: {    
    all: {
      id:'all', 
      trkey: 'universal_category_all', 
      group: 'g1'
    },
    veg: {
      id:'veg',
       trkey: 'universal_type_faza_veg', 
       group: 'g2'
    },
    flo: {
      id:'flo',
       trkey: 'universal_type_faza_flo', 
       group: 'g3'
    },
    har: {
      id:'har',
       trkey: 'universal_type_faza_har', 
       group: 'g4'
    },
    flo_har: {
      id:'flo_har', 
      trkey: ['universal_type_faza_flo','universal_type_faza_har'], 
      group: 'g7'
    },
    photo: {
      id:'photo', 
      trkey: 'universal_category_photo', 
      group: 'g5'
    },
    video: {
      id:'video', 
      trkey: 'universal_category_video', 
      group: 'g6'
    },

    week: {
      id: 'week',
      trkey: 'universal_category_all_weeks',
      group: 'w16',
      not_countable: true,
      childs: {
        'week:1': {
          id: 'week:1',
          name: '1',
          trkey: null,        
        },  
        'week:2': {
          id: 'week:2',
          name: '2',
          trkey: null,        
        },  
        'week:3': {
          id: 'week:3',
          name: '3',
          trkey: null,        
        },  
        'week:4': {
          id: 'week:4',
          name: '4',
          trkey: null,        
        },  
        'week:5': {
          id: 'week:5',
          name: '5',
          trkey: null,        
        },  
        'week:6': {
          id: 'week:6',
          name: '6',
          trkey: null,        
        },  
        'week:7': {
          id: 'week:7',
          name: '7',
          trkey: null,        
        },  
        'week:8': {
          id: 'week:8',
          name: '8',
          trkey: null,        
        },  
        'week:9': {
          id: 'week:9',
          name: '9',
          trkey: null,        
        },  
        'week:10': {
          id: 'week:10',
          name: '10',
          trkey: null,        
        },  
        'week:11': {
          id: 'week:11',
          name: '11',
          trkey: null,        
        },  
        'week:12': {
          id: 'week:12',
          name: '12',
          trkey: null,        
        },  
        'week:13': {
          id: 'week:13',
          name: '13',
          trkey: null,        
        },  
        
      }
    },
  
  },  
  tagsBrandSeedProducts: {    
    all: {
      id:'all', 
      trkey: 'universal_category_all', 
      group: 'g1'
    },
    // fem: {
    //   id:'fem',
    //    trkey: 'universal_category_fem', 
    //    group: 'g1'
    // },
    // reg: {
    //   id:'reg',
    //    trkey: 'universal_category_reg', 
    //    group: 'g2'
    // },
    auto: {
      id:'auto', 
      trkey: 'universal_category_autoflower', 
      group: 'g3'
    },
    photo: {
      id:'photo', 
      trkey: 'universal_category_photoperiod', 
      group: 'g3'
    },
    // tests: {
    //   id:'tests', 
    //   trkey: 'strain_view_test', 
    //   group: 'g4'
    // },
    // new: {
    //   id:'new',
    //   trkey: 'universal_strain_view_release_year', 
    //   params: {
    //     year: new Date().getFullYear()
    //   }, 
    //   group: 'g5'
    // },
    // last: {
    //   id:'last', 
    //   trkey: 'universal_strain_view_release_year', 
    //   params: {
    //     year: new Date().getFullYear() - 1
    //   }, 
    //   group: 'g5'
    // },
    // lastl: {
    //   id:'lastl', 
    //   trkey: 'universal_strain_view_release_year', 
    //   params: {
    //     year: new Date().getFullYear() - 2
    //   }, 
    //   group: 'g5'
    // },
    // old2: {
    //   id:'old2', 
    //   trkey: 'strain_view_previously_released', 
    //   group: 'g5'
    // },

    // released: {
    //   id: "released",
    //   trkey: "Release year",
    //   single_choose: true,
    //   not_countable: true,
    //   childs: {
    //     new: {
    //       id: "new",
    //       trkey: new Date().getFullYear(),
    //       full_trkey: new Date().getFullYear()
    //     }, 
    //     last: {
    //       id: "last",
    //       trkey: new Date().getFullYear() - 1,
    //       full_trkey: new Date().getFullYear() - 1
    //     }, 
    //     lastl: {
    //       id: "lastl",
    //       trkey: new Date().getFullYear() - 2,
    //       full_trkey: new Date().getFullYear() - 2
    //     }, 
    //     old2: {
    //       id: "old2",
    //       trkey: "strain_view_previously_released",
    //       full_trkey: "strain_view_previously_released"
    //     },
    //   },
    // },

    // discontinued: {
    //   id:'discontinued', 
    //   trkey: 'strain_view_discontinued', 
    //   group: 'g7'
    // },
  
  },    
  tagsQuestions: {    
    all: {
      id: 'all', 
      trkey: 'universal_category_all', 
      group: 'g1'
    },
    new: {
      id: 'new', 
      trkey: 'universal_category_new', 
      group: 'g5'
    },
    opened: {
      id: 'opened', 
      trkey: 'grow_questions_view_category_opened', 
      group: 'g6'
    },
    closed: {
      id: 'closed', 
      trkey: 'grow_questions_view_category_solved', 
      group: 'g7'
    },
    no_diary: {
      id: 'no_diary', 
      trkey: 'grow_questions_view_without_diary', 
      group: 'g8'
    },
    veg: {
      id: 'veg', 
      trkey: 'universal_type_faza_veg', 
      group: 'g2'
    },
    flo: {
      id: 'flo', 
      trkey: 'universal_type_faza_flo', 
      group: 'g3'
    },
    har: {
      id: 'har', 
      trkey: 'universal_type_faza_har', 
      group: 'g4'},
  
    my: {
      id: 'my', 
      trkey: 'universal_my_questions', 
      group: 'g9'
    },
    my_solution: {
      id: 'my_solution', 
      trkey: 'universal_my_question_solutions', 
      group: 'g10'
    },
  
    symptom_top_1: {
      id: "symptom_top_1",
      trkey: "universal_plant_symptoms_type_1",
      not_countable: true,
      childs: {
        symptom_1: {
          id: "symptom_1",
          trkey: "universal_plant_symptom_1",
          full_trkey: "universal_plant_symptoms_type_1. Not fattening"
         },
        symptom_2: {
          id: "symptom_2",
          trkey: "universal_plant_symptom_2",
          full_trkey: "universal_plant_symptoms_type_1. Too few"
         },
        symptom_3: {
          id: "symptom_3",
          trkey: "universal_plant_symptom_3",
          full_trkey: "universal_plant_symptoms_type_1. Other"
         }
       }
     },
    symptom_top_2: {
      id: "symptom_top_2",
      trkey: "universal_plant_symptoms_type_2",
      not_countable: true,
      childs: {
        symptom_24: {
          id: "symptom_24",
          trkey: "universal_plant_symptom_24",
          full_trkey: "universal_plant_symptoms_type_2. Color - Black or grey"
         },
        symptom_23: {
          id: "symptom_23",
          trkey: "universal_plant_symptom_23",
          full_trkey: "universal_plant_symptoms_type_2. Color - Dark-brown"
         },
        symptom_22: {
          id: "symptom_22",
          trkey: "universal_plant_symptom_22",
          full_trkey: "universal_plant_symptoms_type_2. Color - Dark-purple"
         },
        symptom_25: {
          id: "symptom_25",
          trkey: "universal_plant_symptom_25",
          full_trkey: "universal_plant_symptoms_type_2. Color - Mottling"
         },
        symptom_21: {
          id: "symptom_21",
          trkey: "universal_plant_symptom_21",
          full_trkey: "universal_plant_symptoms_type_2. Color - Pale"
         },
        symptom_20: {
          id: "symptom_20",
          trkey: "universal_plant_symptom_20",
          full_trkey: "universal_plant_symptoms_type_2. Color - Red or pink"
         },
        symptom_19: {
          id: "symptom_19",
          trkey: "universal_plant_symptom_19",
          full_trkey: "universal_plant_symptoms_type_2. Color - Yellow"
         },
        symptom_11: {
          id: "symptom_11",
          trkey: "universal_plant_symptom_11",
          full_trkey: "universal_plant_symptoms_type_2. Curl down"
         },
        symptom_10: {
          id: "symptom_10",
          trkey: "universal_plant_symptom_10",
          full_trkey: "universal_plant_symptoms_type_2. Curl up"
         },
        symptom_12: {
          id: "symptom_12",
          trkey: "universal_plant_symptom_12",
          full_trkey: "universal_plant_symptoms_type_2. Dropping off"
         },
        symptom_6: {
          id: "symptom_6",
          trkey: "universal_plant_symptom_6",
          full_trkey: "universal_plant_symptoms_type_2. Edges burnt"
         },
        symptom_7: {
          id: "symptom_7",
          trkey: "universal_plant_symptom_7",
          full_trkey: "universal_plant_symptoms_type_2. Tips - Burnt"
         },
        symptom_8: {
          id: "symptom_8",
          trkey: "universal_plant_symptom_8",
          full_trkey: "universal_plant_symptoms_type_2. Tips - Die"
         },
        symptom_9: {
          id: "symptom_9",
          trkey: "universal_plant_symptom_9",
          full_trkey: "universal_plant_symptoms_type_2. Tips - Thick"
         },
        symptom_5: {
          id: "symptom_5",
          trkey: "universal_plant_symptom_5",
          full_trkey: "universal_plant_symptoms_type_2. Too few"
         },
        symptom_4: {
          id: "symptom_4",
          trkey: "universal_plant_symptom_4",
          full_trkey: "universal_plant_symptoms_type_2. Too many"
         },
        symptom_13: {
          id: "symptom_13",
          trkey: "universal_plant_symptom_13",
          full_trkey: "universal_plant_symptoms_type_2. Twisted"
         },
        symptom_18: {
          id: "symptom_18",
          trkey: "universal_plant_symptom_18",
          full_trkey: "universal_plant_symptoms_type_2. Veins - stay green"
         },
        symptom_17: {
          id: "symptom_17",
          trkey: "universal_plant_symptom_17",
          full_trkey: "universal_plant_symptoms_type_2. Veins - yellow between"
         },
        symptom_14: {
          id: "symptom_14",
          trkey: "universal_plant_symptom_14",
          full_trkey: "universal_plant_symptoms_type_2. Webbing"
         },
        symptom_16: {
          id: "symptom_16",
          trkey: "universal_plant_symptom_16",
          full_trkey: "universal_plant_symptoms_type_2. White powder"
         },
        symptom_15: {
          id: "symptom_15",
          trkey: "universal_plant_symptom_15",
          full_trkey: "universal_plant_symptoms_type_2. Wilting"
         },
        symptom_26: {
          id: "symptom_26",
          trkey: "universal_plant_symptom_26",
          full_trkey: "universal_plant_symptoms_type_2. Other"
         }
       }
     },
    symptom_top_3: {
      id: "symptom_top_3",
      trkey: "universal_plant_symptoms_type_3",
      not_countable: true,
      childs: {
        symptom_36: {
          id: "symptom_36",
          trkey: "universal_plant_symptom_36",
          full_trkey: "universal_plant_symptoms_type_3. Spaces between nodes"
         },
        symptom_40: {
          id: "symptom_40",
          trkey: "universal_plant_symptom_40",
          full_trkey: "universal_plant_symptoms_type_3. Stem - Red or purple"
         },
        symptom_39: {
          id: "symptom_39",
          trkey: "universal_plant_symptom_39",
          full_trkey: "universal_plant_symptoms_type_3. Stem - Weak"
         },
        symptom_34: {
          id: "symptom_34",
          trkey: "universal_plant_symptom_34",
          full_trkey: "universal_plant_symptoms_type_3. Too short"
         },
        symptom_35: {
          id: "symptom_35",
          trkey: "universal_plant_symptom_35",
          full_trkey: "universal_plant_symptoms_type_3. Too tall"
         },
        symptom_37: {
          id: "symptom_37",
          trkey: "universal_plant_symptom_37",
          full_trkey: "universal_plant_symptoms_type_3. Twisted"
         },
        symptom_38: {
          id: "symptom_38",
          trkey: "universal_plant_symptom_38",
          full_trkey: "universal_plant_symptoms_type_3. Wilting"
         },
        symptom_41: {
          id: "symptom_41",
          trkey: "universal_plant_symptom_41",
          full_trkey: "universal_plant_symptoms_type_3. Other"
         }
       }
     },
    symptom_top_4: {
      id: "symptom_top_4",
      trkey: "universal_plant_symptoms_type_4",
      not_countable: true,
      childs: {
        symptom_42: {
          id: "symptom_42",
          trkey: "universal_plant_symptom_42",
          full_trkey: "universal_plant_symptoms_type_4. Color - Brown"
         },
        symptom_43: {
          id: "symptom_43",
          trkey: "universal_plant_symptom_43",
          full_trkey: "universal_plant_symptoms_type_4. Mushy"
         },
        symptom_45: {
          id: "symptom_45",
          trkey: "universal_plant_symptom_45",
          full_trkey: "universal_plant_symptoms_type_4. Smelly"
         },
        symptom_44: {
          id: "symptom_44",
          trkey: "universal_plant_symptom_44",
          full_trkey: "universal_plant_symptoms_type_4. Too few"
         },
        symptom_46: {
          id: "symptom_46",
          trkey: "universal_plant_symptom_46",
          full_trkey: "universal_plant_symptoms_type_4. Other"
         }
       }
     },
    symptom_top_5: {
      id: "symptom_top_5",
      trkey: "universal_plant_symptoms_type_5",
      not_countable: true,
      childs: {
        symptom_50: {
          id: "symptom_50",
          trkey: "universal_plant_symptom_50",
          full_trkey: "universal_plant_symptoms_type_5. Clones"
         },
        symptom_53: {
          id: "symptom_53",
          trkey: "universal_plant_symptom_53",
          full_trkey: "universal_plant_symptoms_type_5. Lighting"
         },
        symptom_56: {
          id: "symptom_56",
          trkey: "universal_plant_symptom_56",
          full_trkey: "universal_plant_symptoms_type_5. Outdoor"
         },
        symptom_55: {
          id: "symptom_55",
          trkey: "universal_plant_symptom_55",
          full_trkey: "universal_plant_symptoms_type_5. Seedling"
         },
        symptom_49: {
          id: "symptom_49",
          trkey: "universal_plant_symptom_49",
          full_trkey: "universal_plant_symptoms_type_5. Seeds"
         },
        symptom_52: {
          id: "symptom_52",
          trkey: "universal_plant_symptom_52",
          full_trkey: "universal_plant_symptoms_type_5. Sensors"
         },
        symptom_48: {
          id: "symptom_48",
          trkey: "universal_plant_symptom_48",
          full_trkey: "universal_plant_symptoms_type_5. Strain - Autoflowering"
         },
        symptom_47: {
          id: "symptom_47",
          trkey: "universal_plant_symptom_47",
          full_trkey: "universal_plant_symptoms_type_5. Strains - Photoperiod"
         },
        symptom_51: {
          id: "symptom_51",
          trkey: "universal_plant_symptom_51",
          full_trkey: "universal_plant_symptoms_type_5. Substrates"
         },
        symptom_54: {
          id: "symptom_54",
          trkey: "universal_plant_symptom_54",
          full_trkey: "universal_plant_symptoms_type_5. Ventilation"
         },
        symptom_57: {
          id: "symptom_57",
          trkey: "universal_plant_symptom_57",
          full_trkey: "universal_plant_symptoms_type_5. Other"
         }
       }
     },
    symptom_top_6: {
      id: "symptom_top_6",
      trkey: "universal_plant_symptoms_type_6",
      not_countable: true,
      childs: {
        symptom_58: {
          id: "symptom_58",
          trkey: "universal_plant_symptom_58",
          full_trkey: "universal_plant_symptoms_type_6. Automatic systems"
         },
        symptom_60: {
          id: "symptom_60",
          trkey: "universal_plant_symptom_60",
          full_trkey: "universal_plant_symptoms_type_6. Chemical composition"
         },
        symptom_61: {
          id: "symptom_61",
          trkey: "universal_plant_symptom_61",
          full_trkey: "universal_plant_symptoms_type_6. Deficiences"
         },
        symptom_59: {
          id: "symptom_59",
          trkey: "universal_plant_symptom_59",
          full_trkey: "universal_plant_symptoms_type_6. Schedule"
         },
        symptom_62: {
          id: "symptom_62",
          trkey: "universal_plant_symptom_62",
          full_trkey: "universal_plant_symptoms_type_6. Other"
         }
       }
     },
    symptom_top_7: {
      id: "symptom_top_7",
      trkey: "universal_plant_symptoms_type_7",
      not_countable: true,
      childs: {
        symptom_65: {
          id: "symptom_65",
          trkey: "universal_plant_symptom_65",
          full_trkey: "universal_plant_symptoms_type_7. 12-12 from seeds"
         },
        symptom_71: {
          id: "symptom_71",
          trkey: "universal_plant_symptom_71",
          full_trkey: "universal_plant_symptoms_type_7. Defoliation"
         },
        symptom_69: {
          id: "symptom_69",
          trkey: "universal_plant_symptom_69",
          full_trkey: "universal_plant_symptoms_type_7. FIMing"
         },
        symptom_64: {
          id: "symptom_64",
          trkey: "universal_plant_symptom_64",
          full_trkey: "universal_plant_symptoms_type_7. HST"
         },
        symptom_63: {
          id: "symptom_63",
          trkey: "universal_plant_symptom_63",
          full_trkey: "universal_plant_symptoms_type_7. LST"
         },
        symptom_70: {
          id: "symptom_70",
          trkey: "universal_plant_symptom_70",
          full_trkey: "universal_plant_symptoms_type_7. Main-lining"
         },
        symptom_67: {
          id: "symptom_67",
          trkey: "universal_plant_symptom_67",
          full_trkey: "universal_plant_symptoms_type_7. ScrOG"
         },
        symptom_66: {
          id: "symptom_66",
          trkey: "universal_plant_symptom_66",
          full_trkey: "universal_plant_symptoms_type_7. SoG"
         },
        symptom_68: {
          id: "symptom_68",
          trkey: "universal_plant_symptom_68",
          full_trkey: "universal_plant_symptoms_type_7. Topping"
         },
        symptom_72: {
          id: "symptom_72",
          trkey: "universal_plant_symptom_72",
          full_trkey: "universal_plant_symptoms_type_7. Other"
         }
       }
     },
    symptom_top_8: {
      id: "symptom_top_8",
      trkey: "universal_plant_symptoms_type_8",
      not_countable: true,
      childs: {
        symptom_27: {
          id: "symptom_27",
          trkey: "universal_plant_symptom_27",
          full_trkey: "universal_plant_symptoms_type_8. Bugs"
         },
        symptom_32: {
          id: "symptom_32",
          trkey: "universal_plant_symptom_32",
          full_trkey: "universal_plant_symptoms_type_8. General questions"
         },
        symptom_30: {
          id: "symptom_30",
          trkey: "universal_plant_symptom_30",
          full_trkey: "universal_plant_symptoms_type_8. Harvest - Curing"
         },
        symptom_29: {
          id: "symptom_29",
          trkey: "universal_plant_symptom_29",
          full_trkey: "universal_plant_symptoms_type_8. Harvest - Drying"
         },
        symptom_31: {
          id: "symptom_31",
          trkey: "universal_plant_symptom_31",
          full_trkey: "universal_plant_symptoms_type_8. Harvest - Smoking"
         },
        symptom_28: {
          id: "symptom_28",
          trkey: "universal_plant_symptom_28",
          full_trkey: "universal_plant_symptoms_type_8. Mold"
         },
        symptom_33: {
          id: "symptom_33",
          trkey: "universal_plant_symptom_33",
          full_trkey: "universal_plant_symptoms_type_8. Other"
         }
       }
     }, 
  },
  tagsBrands: {    
    all: {
      id:'all', 
      trkey: 'universal_category_all', 
      group: 'g0'
    },   
  },   
  tagsStrains: {    
    all: {
      id:'all', 
      trkey: 'universal_category_all', 
      group: 'g0'
    },
    photo: {
      id:'photo',
       trkey: 'Photoperiod', 
       group: 'g1'
    },
    auto: {
      id:'auto',
      trkey: 'universal_category_autoflower', 
      group: 'g1'
    },
    fem: {
      id:'fem',
       trkey: 'universal_category_fem', 
       group: 'g2'
    },
    reg: {
      id:'reg',
       trkey: 'universal_category_reg', 
       group: 'g2'
    },  
    released: {
      id: "released",
      trkey: "Release year",
      single_choose: true,
      not_countable: true,
      childs: {
        new: {
          id: "new",
          trkey: new Date().getFullYear(),
          full_trkey: new Date().getFullYear()
        }, 
        last: {
          id: "last",
          trkey: new Date().getFullYear() - 1,
          full_trkey: new Date().getFullYear() - 1
        }, 
        lastl: {
          id: "lastl",
          trkey: new Date().getFullYear() - 2,
          full_trkey: new Date().getFullYear() - 2
        }, 
        old: {
          id: "old",
          trkey: "strain_view_previously_released",
          full_trkey: "strain_view_previously_released"
        },
      },
    },

    // discontinued: {
    //   id:'discontinued', 
    //   trkey: 'strain_view_discontinued', 
    //   group: 'g7'
    // },
  
  },
  tagsStrains2: {    
    all: {
      id:'all', 
      trkey: 'universal_category_all', 
      group: 'g0'
    }, 
    genes_indica: {
      id:'genes_indica',
      trkey: 'Indica', 
      group: 'g1'
    },  
    genes_sativa: {
      id:'genes_sativa',
      trkey: 'Sativa', 
      group: 'g1'
    },
    genes_hybrid: {
      id:'genes_hybrid',
      trkey: 'Hybrid', 
      group: 'g1'
    },
    thc_high: {
      id:'thc_high',
      trkey: 'High THC', 
      group: 'g2'
    },  
    tastes: {
      id: "tastes",
      trkey: "Flavour",
      single_choose: true,
      not_countable: true,
      childs: { 
        tastes_ammonia: {
          id: "ammonia",
          trkey: "Ammonia",
          full_trkey: "Ammonia"
        },
        tastes_apple: {
          id: "apple",
          trkey: "Apple",
          full_trkey: "Apple"
        },
        tastes_apricot: {
          id: "apricot",
          trkey: "Apricot",
          full_trkey: "Apricot"
        },
        tastes_berry: {
          id: "berry",
          trkey: "Berry",
          full_trkey: "Berry"
        },
        tastes_blue_cheese: {
          id: "blue_cheese",
          trkey: "Blue cheese",
          full_trkey: "Blue cheese"
        },
        tastes_blueberry: {
          id: "blueberry",
          trkey: "Blueberry",
          full_trkey: "Blueberry"
        },
        tastes_butter: {
          id: "butter",
          trkey: "Butter",
          full_trkey: "Butter"
        },
        tastes_cheese: {
          id: "cheese",
          trkey: "Cheese",
          full_trkey: "Cheese"
        },
        tastes_chemical: {
          id: "chemical",
          trkey: "Chemical",
          full_trkey: "Chemical"
        },
        tastes_chestnut: {
          id: "chestnut",
          trkey: "Chestnut",
          full_trkey: "Chestnut"
        },
        tastes_citrus: {
          id: "citrus",
          trkey: "Citrus",
          full_trkey: "Citrus"
        },
        tastes_coffee: {
          id: "coffee",
          trkey: "Coffee",
          full_trkey: "Coffee"
        },
        tastes_cream: {
          id: "cream",
          trkey: "Cream",
          full_trkey: "Cream"
        },
        tastes_diesel: {
          id: "diesel",
          trkey: "Diesel",
          full_trkey: "Diesel"
        },
        tastes_earthy: {
          id: "earthy",
          trkey: "Earthy",
          full_trkey: "Earthy"
        },
        tastes_flowery: {
          id: "flowery",
          trkey: "Flowery",
          full_trkey: "Flowery"
        },
        tastes_fruity: {
          id: "fruity",
          trkey: "Fruity",
          full_trkey: "Fruity"
        },
        tastes_grape: {
          id: "grape",
          trkey: "Grape",
          full_trkey: "Grape"
        },
        tastes_grapefruit: {
          id: "grapefruit",
          trkey: "Grapefruit",
          full_trkey: "Grapefruit"
        },
        tastes_honey: {
          id: "honey",
          trkey: "Honey",
          full_trkey: "Honey"
        },
        tastes_lavender: {
          id: "lavender",
          trkey: "Lavender",
          full_trkey: "Lavender"
        },
        tastes_lemon: {
          id: "lemon",
          trkey: "Lemon",
          full_trkey: "Lemon"
        },
        tastes_lime: {
          id: "lime",
          trkey: "Lime",
          full_trkey: "Lime"
        },
        tastes_mango: {
          id: "mango",
          trkey: "Mango",
          full_trkey: "Mango"
        },
        tastes_menthol: {
          id: "menthol",
          trkey: "Menthol",
          full_trkey: "Menthol"
        },
        tastes_mint: {
          id: "mint",
          trkey: "Mint",
          full_trkey: "Mint"
        },
        tastes_nutty: {
          id: "nutty",
          trkey: "Nutty",
          full_trkey: "Nutty"
        },
        tastes_orange: {
          id: "orange",
          trkey: "Orange",
          full_trkey: "Orange"
        },
        tastes_peach: {
          id: "peach",
          trkey: "Peach",
          full_trkey: "Peach"
        },
        tastes_pear: {
          id: "pear",
          trkey: "Pear",
          full_trkey: "Pear"
        },
        tastes_pepper: {
          id: "pepper",
          trkey: "Pepper",
          full_trkey: "Pepper"
        },
        tastes_pine: {
          id: "pine",
          trkey: "Pine",
          full_trkey: "Pine"
        },
        tastes_pineapple: {
          id: "pineapple",
          trkey: "Pineapple",
          full_trkey: "Pineapple"
        },
        tastes_plum: {
          id: "plum",
          trkey: "Plum",
          full_trkey: "Plum"
        },
        tastes_pungent: {
          id: "pungent",
          trkey: "Pungent",
          full_trkey: "Pungent"
        },
        tastes_rose: {
          id: "rose",
          trkey: "Rose",
          full_trkey: "Rose"
        },
        tastes_sage: {
          id: "sage",
          trkey: "Sage",
          full_trkey: "Sage"
        },
        tastes_skunk: {
          id: "skunk",
          trkey: "Skunk",
          full_trkey: "Skunk"
        },
        tastes_sour: {
          id: "sour",
          trkey: "Sour",
          full_trkey: "Sour"
        },
        tastes_spicy_herbal: {
          id: "spicy_herbal",
          trkey: "Spicy herbal",
          full_trkey: "Spicy herbal"
        },
        tastes_strawberry: {
          id: "strawberry",
          trkey: "Strawberry",
          full_trkey: "Strawberry"
        },
        tastes_sweet: {
          id: "sweet",
          trkey: "Sweet",
          full_trkey: "Sweet"
        },
        tastes_tar: {
          id: "tar",
          trkey: "Tar",
          full_trkey: "Tar"
        },
        tastes_tea: {
          id: "tea",
          trkey: "Tea",
          full_trkey: "Tea"
        },
        tastes_tobacco: {
          id: "tobacco",
          trkey: "Tobacco",
          full_trkey: "Tobacco"
        },
        tastes_tree_fruit: {
          id: "tree_fruit",
          trkey: "Tree fruit",
          full_trkey: "Tree fruit"
        },
        tastes_tropical: {
          id: "tropical",
          trkey: "Tropical",
          full_trkey: "Tropical"
        },
        tastes_vanilla: {
          id: "vanilla",
          trkey: "Vanilla",
          full_trkey: "Vanilla"
        },
        tastes_violet: {
          id: "violet",
          trkey: "Violet",
          full_trkey: "Violet"
        },
        tastes_woody: {
          id: "woody",
          trkey: "Woody",
          full_trkey: "Woody"
        } 
      },
    },
    positive: {
      id: "positive",
      trkey: "Effects",
      single_choose: true,
      not_countable: true,
      childs: {   
        positive_aroused: {
          id: "Aroused",
          trkey: "Aroused",
          full_trkey: "Aroused"
        },
        positive_creative: {
          id: "Creative",
          trkey: "Creative",
          full_trkey: "Creative"
        },
        positive_energetic: {
          id: "Energetic",
          trkey: "Energetic",
          full_trkey: "Energetic"
        },
        positive_euphoric: {
          id: "Euphoric",
          trkey: "Euphoric",
          full_trkey: "Euphoric"
        },
        positive_focused: {
          id: "Focused",
          trkey: "Focused",
          full_trkey: "Focused"
        },
        positive_giggly: {
          id: "Giggly",
          trkey: "Giggly",
          full_trkey: "Giggly"
        },
        positive_happy: {
          id: "Happy",
          trkey: "Happy",
          full_trkey: "Happy"
        },
        positive_hungry: {
          id: "Hungry",
          trkey: "Hungry",
          full_trkey: "Hungry"
        },
        positive_relaxed: {
          id: "Relaxed",
          trkey: "Relaxed",
          full_trkey: "Relaxed"
        },
        positive_sleepy: {
          id: "Sleepy",
          trkey: "Sleepy",
          full_trkey: "Sleepy"
        },
        positive_talkative: {
          id: "Talkative",
          trkey: "Talkative",
          full_trkey: "Talkative"
        },
        positive_tingly: {
          id: "Tingly",
          trkey: "Tingly",
          full_trkey: "Tingly"
        },
        positive_uplifted: {
          id: "Uplifted",
          trkey: "Uplifted",
          full_trkey: "Uplifted"
        }, 
      },
    }, 
    medical: {
      id: "medical",
      trkey: "Helps With",
      single_choose: true,
      not_countable: true,
      childs: {  
        medical_add_adhd: {
          id: "ADD/ADHD",
          trkey: "ADD/ADHD",
          full_trkey: "ADD/ADHD"
        },
        medical_alzheimers: {
          id: "Alzheimer's",
          trkey: "Alzheimer's",
          full_trkey: "Alzheimer's"
        },
        medical_anorexia: {
          id: "Anorexia",
          trkey: "Anorexia",
          full_trkey: "Anorexia"
        },
        medical_anxiety: {
          id: "Anxiety",
          trkey: "Anxiety",
          full_trkey: "Anxiety"
        },
        medical_arthritis: {
          id: "Arthritis",
          trkey: "Arthritis",
          full_trkey: "Arthritis"
        },
        medical_asthma: {
          id: "Asthma",
          trkey: "Asthma",
          full_trkey: "Asthma"
        },
        medical_bipolar_disorder: {
          id: "Bipolar disorder",
          trkey: "Bipolar disorder",
          full_trkey: "Bipolar disorder"
        },
        medical_cachexia: {
          id: "Cachexia",
          trkey: "Cachexia",
          full_trkey: "Cachexia"
        },
        medical_cancer: {
          id: "Cancer",
          trkey: "Cancer",
          full_trkey: "Cancer"
        },
        medical_cramps: {
          id: "Cramps",
          trkey: "Cramps",
          full_trkey: "Cramps"
        },
        medical_crohns_disease: {
          id: "Crohn's disease",
          trkey: "Crohn's disease",
          full_trkey: "Crohn's disease"
        },
        medical_depression: {
          id: "Depression",
          trkey: "Depression",
          full_trkey: "Depression"
        },
        medical_epilepsy: {
          id: "Epilepsy",
          trkey: "Epilepsy",
          full_trkey: "Epilepsy"
        },
        medical_eye_pressure: {
          id: "Eye pressure",
          trkey: "Eye pressure",
          full_trkey: "Eye pressure"
        },
        medical_fatigue: {
          id: "Fatigue",
          trkey: "Fatigue",
          full_trkey: "Fatigue"
        },
        medical_fibromyalgia: {
          id: "Fibromyalgia",
          trkey: "Fibromyalgia",
          full_trkey: "Fibromyalgia"
        },
        medical_gastrointestinal_disorder: {
          id: "Gastrointestinal disorder",
          trkey: "Gastrointestinal disorder",
          full_trkey: "Gastrointestinal disorder"
        },
        medical_glaucoma: {
          id: "Glaucoma",
          trkey: "Glaucoma",
          full_trkey: "Glaucoma"
        },
        medical_headaches: {
          id: "Headaches",
          trkey: "Headaches",
          full_trkey: "Headaches"
        },
        medical_hiv_aids: {
          id: "HIV/AIDS",
          trkey: "HIV/AIDS",
          full_trkey: "HIV/AIDS"
        },
        medical_hypertension: {
          id: "Hypertension",
          trkey: "Hypertension",
          full_trkey: "Hypertension"
        },
        medical_inflammation: {
          id: "Inflammation",
          trkey: "Inflammation",
          full_trkey: "Inflammation"
        },
        medical_insomnia: {
          id: "Insomnia",
          trkey: "Insomnia",
          full_trkey: "Insomnia"
        },
        medical_lack_of_appetite: {
          id: "Lack of appetite",
          trkey: "Lack of appetite",
          full_trkey: "Lack of appetite"
        },
        medical_migraines: {
          id: "Migraines",
          trkey: "Migraines",
          full_trkey: "Migraines"
        },
        medical_multiple_sclerosis: {
          id: "Multiple sclerosis",
          trkey: "Multiple sclerosis",
          full_trkey: "Multiple sclerosis"
        },
        medical_muscle_spasms: {
          id: "Muscle spasms",
          trkey: "Muscle spasms",
          full_trkey: "Muscle spasms"
        },
        medical_muscular_dystrophy: {
          id: "Muscular dystrophy",
          trkey: "Muscular dystrophy",
          full_trkey: "Muscular dystrophy"
        },
        medical_nausea: {
          id: "Nausea",
          trkey: "Nausea",
          full_trkey: "Nausea"
        },
        medical_pain: {
          id: "Pain",
          trkey: "Pain",
          full_trkey: "Pain"
        },
        medical_parkinsons: {
          id: "Parkinson's",
          trkey: "Parkinson's",
          full_trkey: "Parkinson's"
        },
        medical_phantom: {
          id: "Phantom limb pain",
          trkey: "Phantom limb pain",
          full_trkey: "Phantom limb pain"
        },
        medical_pms: {
          id: "PMS",
          trkey: "PMS",
          full_trkey: "PMS"
        },
        medical_ptsd: {
          id: "PTSD",
          trkey: "PTSD",
          full_trkey: "PTSD"
        },
        medical_seizures: {
          id: "Seizures",
          trkey: "Seizures",
          full_trkey: "Seizures"
        },
        medical_spasticity: {
          id: "Spasticity",
          trkey: "Spasticity",
          full_trkey: "Spasticity"
        },
        medical_spinal_cord_injury: {
          id: "Spinal cord injury",
          trkey: "Spinal cord injury",
          full_trkey: "Spinal cord injury"
        },
        medical_stress: {
          id: "Stress",
          trkey: "Stress",
          full_trkey: "Stress"
        },
        medical_tinnitus: {
          id: "Tinnitus",
          trkey: "Tinnitus",
          full_trkey: "Tinnitus"
        },
        medical_tourettes_syndrome: {
          id: "Tourette's syndrome",
          trkey: "Tourette's syndrome",
          full_trkey: "Tourette's syndrome"
        }
      },
    }, 
  
  },
  tagsShorts: {    
    all: {
      id:'all', 
      trkey: 'universal_category_all', 
      group: 'g0'
    },
    photo: {
      id:'photo',
       trkey: 'Photoperiod', 
       group: 'g1'
    },
    auto: {
      id:'auto',
      trkey: 'universal_category_autoflower', 
      group: 'g1'
    },
    veg: {
      id:'veg',
       trkey: 'universal_type_faza_veg', 
       group: 'g2'
    },
    flo: {
      id:'flo',
       trkey: 'universal_type_faza_flo', 
       group: 'g3'
    },
    har: {
      id:'har',
       trkey: 'universal_type_faza_har', 
       group: 'g4'
    },
    flo_har: {
      id:'flo_har', 
      trkey: ['universal_type_faza_flo','universal_type_faza_har'], 
      group: 'g7'
    },  

    'week:1': {
      id: 'week:1',
      name: 'Week 1',
      trkey: null,
    },  
    'week:2': {
      id: 'week:2',
      name: 'Week 2',
      trkey: null,    
    },  
    'week:3': {
      id: 'week:3',
      name: 'Week 3',
      trkey: null,
    },  
    'week:4': {
      id: 'week:4',
      name: 'Week 4',
      trkey: null,
    },  
    'week:5': {
      id: 'week:5',
      name: 'Week 5',
      trkey: null,  
    },  
    'week:6': {
      id: 'week:6',
      name: 'Week 6',
      trkey: null,   
    },   
    week: {
      id: 'week',
      trkey: 'universal_category_all_weeks',
      group: 'w16',
      not_countable: true,
      childs: {       
        'week:7': {
          id: 'week:7',
          name: '7',
          trkey: null,        
        },  
        'week:8': {
          id: 'week:8',
          name: '8',
          trkey: null,        
        },  
        'week:9': {
          id: 'week:9',
          name: '9',
          trkey: null,        
        },  
        'week:10': {
          id: 'week:10',
          name: '10',
          trkey: null,        
        },  
        'week:11': {
          id: 'week:11',
          name: '11',
          trkey: null,        
        },  
        'week:12': {
          id: 'week:12',
          name: '12',
          trkey: null,        
        },  
        'week:13': {
          id: 'week:13',
          name: '13',
          trkey: null,        
        },  
        
      }
    },

    // week: {
    //   id: 'week',
    //   trkey: 'universal_category_all_weeks',
    //   group: 'w16',
    //   not_countable: true,
    //   childs: {
    //     'week:1': {
    //       id: 'week:1',
    //       name: '1',
    //       trkey: null,        
    //     },  
    //     'week:2': {
    //       id: 'week:2',
    //       name: '2',
    //       trkey: null,        
    //     },  
    //     'week:3': {
    //       id: 'week:3',
    //       name: '3',
    //       trkey: null,        
    //     },  
    //     'week:4': {
    //       id: 'week:4',
    //       name: '4',
    //       trkey: null,        
    //     },  
    //     'week:5': {
    //       id: 'week:5',
    //       name: '5',
    //       trkey: null,        
    //     },  
    //     'week:6': {
    //       id: 'week:6',
    //       name: '6',
    //       trkey: null,        
    //     },  
    //     'week:7': {
    //       id: 'week:7',
    //       name: '7',
    //       trkey: null,        
    //     },  
    //     'week:8': {
    //       id: 'week:8',
    //       name: '8',
    //       trkey: null,        
    //     },  
    //     'week:9': {
    //       id: 'week:9',
    //       name: '9',
    //       trkey: null,        
    //     },  
    //     'week:10': {
    //       id: 'week:10',
    //       name: '10',
    //       trkey: null,        
    //     },  
    //     'week:11': {
    //       id: 'week:11',
    //       name: '11',
    //       trkey: null,        
    //     },  
    //     'week:12': {
    //       id: 'week:12',
    //       name: '12',
    //       trkey: null,        
    //     },  
    //     'week:13': {
    //       id: 'week:13',
    //       name: '13',
    //       trkey: null,        
    //     },  
        
    //   }
    // },
  
  },

  getTagsByType: function(tags, selected, type){
    // var query = [];
    // for(var s of selected){
    //   var tag = tags[s];
    //   if(tag)        
    //     if(tag.type == type)
    //       query.push(tag.val);
    // }    
    // return query;

    var query = [];
    for(var s of selected){
      var params = s.split(':');
      if(s.indexOf(type) == 0){
        if(type == 'kw')
          query.push(params[2]);
        else
          query.push(params[1]);
      }
    }
    return query;
  },
  getTagsWeek: function(tags, selected){    
    return this.getTagsByType(tags, selected, 'week');
  },
  getTagsBrandSeed: function(tags, selected){    
    return this.getTagsByType(tags, selected, 'bsd');
  },
  getTagsSeed: function(tags, selected){    
    return this.getTagsByType(tags, selected, 'sd');
  },
  getTagsBrandNutrient: function(tags, selected){    
    return this.getTagsByType(tags, selected, 'bnt');
  },
  getTagsNutrient: function(tags, selected){    
    return this.getTagsByType(tags, selected, 'nt');
  },
  getTagsBrandLamp: function(tags, selected){    
    return this.getTagsByType(tags, selected, 'blm');
  },
  getTagsLamp: function(tags, selected){    
    return this.getTagsByType(tags, selected, 'lm');
  },
  getTagsBrandTent: function(tags, selected){    
    return this.getTagsByType(tags, selected, 'btn');
  },
  getTagsTent: function(tags, selected){    
    return this.getTagsByType(tags, selected, 'tn');
  },
  getTagsGrower: function(tags, selected){    
    return this.getTagsByType(tags, selected, 'us');
  },
  getTagsKeyword: function(tags, selected, permanent){
    // console.log('getTagsKeyword');
    // console.log(tags);
    // console.log(selected);

    // merge selected and permanent
 
    var all = [];

    if(selected?.length){
      for(var s of selected){
        all.push(s);
      }
    }

    if(permanent?.length){
      for(var s of permanent){
        all.push(s);
      }
    }
    return this.getTagsByType(tags, all, 'kw'); 
  },
  getTagsKeys: function(tags){
    var query = [];
    for(var tags_key in tags){
      query.push(tags_key);
    }
    return query.join(',');
  },
  getTagsSimple: function(tags, selected, permanent){
    // console.log('getTagsSimple', tags, selected);

    // merge selected and permanent

    var all = [];

    if(selected?.length){
      for(var s of selected){
        all.push(s);
      }
    }

    if(permanent?.length){
      for(var s of permanent){
        all.push(s);
      }
    }
 
    // console.log('selected all');
    // console.log(all);
  
    //return selected;
    return all ? all.join(',') : '';

    var query = [];
    for(var s of selected){
      var tag = tags[s];
      if(tag && !tag.type && s.indexOf(':') == -1){     
        query.push(s);
      }
    }

    // for(var tags_key in tags){
    //   var s = tags[tags_key];
    //   if(tags_key.indexOf('kw:') == 0){
    //     continue;
    //   }

    //   if(selected.indexOf(tags_key) != -1){
    //     query.push(tags_key);
    //   }
    //   if(s.childs){
    //     for(var c in s.childs){
    //       if(selected.indexOf(c) != -1){
    //         query.push(c);
    //       }
    //     }
    //   }

    // }

    // console.log(selected);
    // console.log('getTagsSimple', query);
    return query.join(',');
  },
  getTagsSimpleTrKey: function(tags, selected){
    var query = [];
    for(var tags_key in tags){
      var s = tags[tags_key];
      if(tags_key.indexOf('kw:') == 0){
        continue;
      }
      if(tags_key.indexOf(':') >= 0){
        continue;
      }

      if(selected.indexOf(tags_key) != -1){
        query.push(tags[tags_key].trkey);
      }
      if(s.childs){
        for(var c in s.childs){
          if(selected.indexOf(c) != -1){
            query.push(s.childs[c].trkey);
          }
        }
      }
    }
    //console.log('getTagsSimpleTR', query);
    return query;
  }
}

  return {
    provide: {
      tagsUtil: tagsUtil,
    },
  };
})