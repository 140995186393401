
export default defineNuxtRouteMiddleware((to, from) => {

  // if(import.meta.client){
    // if (import.meta.client && window.ga) {
    //   window.ga('set', 'page', route.fullPath);
    //   window.ga('send', 'pageview');
    // }

    const { gtag } = useGtag()

    // // SSR-ready
    // // gtag('event', 'screen_view', {
    // //   app_name: 'My App',
    // //   screen_name: 'Home'
    // // })

    

    // gtag('config', 'G-K1T13ERM3V');

    // gtag('config', 'G-K1T13ERM3V');
    // alert(1);
    // console.log('page_view!!!!!!!');
    // console.log(to);
    // console.log(gtag);

    // gtag('config', 'G-K1T13ERM3V', {
    //   'page_title' : 'User registration :' + to,
    //   'page_path': to,
    //  });

    gtag('event', 'page_view', {
      page_title: document?.title,
      page_location: window?.location.href,
      page_path: to.fullPath,
    })
    // gtag('event', 'screen_view', {
    //   app_name: 'My App',
    //   screen_name: 'Home'
    // })

    // useTrackEvent('login', {
    //   method: 'Google'
    // })


  // }
 
 
})