
export default defineNuxtPlugin((nuxtApp) => {

  // const { add } = useDebugList();

  // nuxtApp.hook('custom:fetch:any', (response) => {
  //   if(response && response.details)
  //     add(response); 
  // });

})


 