export default defineNuxtPlugin(async nuxtApp => {  

  const { $api } = useNuxtApp();
 
  var timerCheck = null;
  var pageVisible = true;


  const initLintenerVisible = () => {
    document.addEventListener("visibilitychange", function() {
      if (document.visibilityState === 'visible') {
        pageVisible = true;
      } else {
        pageVisible = false;
      }
    });
  }

  const loadCountNewNotifications = async function() {
    if(!pageVisible){
      return;
    }
    useUserUpdatesLoading().value = true;
    var data = await $api.getGrowerUpdates(useAuth().getId())
    setTimeout(() => {

      useUserUpdatesNotifications().value = data?.notifications || 0;
      useUserUpdatesChat().value = data?.chat || 0;

      useUserUpdatesLoading().value = false
      
    }, 700);
  }


  var clearIntervalCheckNotifications = () => {
    if(timerCheck){
      clearIntervalCheckNotifications(timerCheck);
      timerCheck = null;
    }
  }

  var setIntervalCheckNotifications = async () => {
    if(!useAuth().isAuth())
      return;

    if(useUserUpdatesLoading().value){
      return false;
    }

    clearIntervalCheckNotifications();
    timerCheck = setInterval(async () => {
      loadCountNewNotifications();
    }, 1000 * 60);

    setTimeout(() => {
      loadCountNewNotifications();
    }, 1000);
  }


  nuxtApp.hook('app:mounted', () => {

    if(import.meta.client){
      initLintenerVisible();
      setIntervalCheckNotifications();
    }
  }) 




})