
export default defineNuxtPlugin(nuxtApp => {  

  var follow = {
   
    addRowList: function(item) {
      console.log('addRowList');
      console.log(item);
      if(item.type == 'diary' && item.finished){
        useFollowBookmark().addRow(item)
      }else{
        useFollowCommon().addRow(item)
      }
      
    },
    deleteRowList: function(item) {
      useFollowCommon().deleteRow(item);
      useFollowBookmark().deleteRow(item); 
    },
    toggle: function(type, id, state, item) {
      if(state){
        this.addRowList(item);
        return this.add(type, id);
      } else {
        this.deleteRowList(item);
        return this.delete(type, id);
      }
    },
    add: function(type, id) {
      return nuxtApp.$api.postFollow(type, id)
    },
    delete: function(type, id) {
      return nuxtApp.$api.deleteFollow(type, id)
    }
  } 
 

return {
  provide: {
    follow: follow,
  },
};

})