<template>

  <template
    v-if="props.data && props.data.id" 
    >
    <a 
      target="_blank" 
      data-position="1" 
      class="cpm_box_in" 
      :href="props.data.redirect"      
      @click="click"
      >

        <div class="adv-visibility" ref="elementObserver"></div>

      
      <div class="imgs">
        <img alt="advice" class="img" :src="props.data.url"></div>
        <div class="title"><div class="name">{{props.data.title}}</div>
        <div class="action_btn">{{props.data.button}}</div>
      </div>
    </a>
  </template> 
  
</template>


<script setup>

const { $observer } = useNuxtApp()

const props = defineProps({
  data: {
    type: Object,
    default: () => ({})
  },
  adv: {
    type: Object,
    default: () => ({})
  },
})
const viewed = ref(false);
const elementObserver = ref(null);

const appear = (res) => {
  if(!viewed.value && res)        
    advBox.viewBox(props.data.id)  
  viewed.value = true;
}

const click = () => {
  advBox.clickBox(props.data.id)
  console.log('clicked');

}
 
onMounted(() => {
  $observer(elementObserver.value, (element) => {
    appear(true);
  });
});

</script>
  

<style>
.cpm_box {
  width: 180px;
  min-width: 170px;
  flex-shrink: 0;
  margin-right: 13px;
}
.cpm_box_in {
  display: flex;
  flex-direction: column;
  vertical-align: top;
  margin-bottom: 10px;
  text-align: left;
  border-radius: 3px;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.4);
}

/* type 1 */

.cpm_box_in {
  background-color: var(--un-background-color-gray);
}

.cpm_box .imgs {}

.cpm_box .img {
  aspect-ratio: 360/248;
  width: 100%;
  display: block;
}
.cpm_box .turn_off {
  display: block;
  color: gray;
  font-size: 0.85rem;
  text-decoration: underline;
    margin-top: -5px;
    text-decoration: underline;
    margin-bottom: 4px;

}

.cpm_box .title {
  display: flex;
  align-items: center;
  height: 100%;
  text-align: center;
  width: 100%;
  font-weight: bold;
  justify-content: center;
  flex-direction: column;
}
.cpm_box .title .name {
  line-height: 1rem;
  height: 40px;
  overflow: hidden;
  display: flex;
  align-items: center;
}


.cpm_box .action_btn {
  display: block;
  padding: 5px 19px;
  background-color: #f5be0a;
  color: #000;
  border-radius: 53px;
  margin-top: 6px;
  font-weight: bold;
  font-size: 0.85rem;
  margin-bottom: 8px;
  max-width: 90%;
}

/**/

@container pb (max-width: 650px) {
  .cpm_box {    
    width: calc(33.3333% - 14px);
    min-width: calc(33.3333% - 14px);
  }
  .cpm_box .imgs {

  }
}


@container pb (max-width: 520px) {
  .cpm_box {    
    width: calc(50% - 20px);
    min-width: calc(50% - 20px);
  }
  .cpm_box .imgs {

  }
}

/* 
@media only screen and (max-width: 500px) {
  .cpm_box {
    width: calc(50% - 8px);
    min-width: calc(50% - 20px);
  }
}

@media only screen and (max-width: 400px) {
  .cpm_box{
     box-shadow: none;
  }
}

@media only screen and (max-width: 350px) {
  .cpm_box {
    max-width: 50%;
  }
} */



.horizontal .cpm_box{
  width: 180px;
  min-width: 170px;
} 


@media only screen and (max-width: 768px) {
  .horizontal .cpm_box{
    width: calc(100vw/3 - 3rem);
    min-width: 160px;
    flex-shrink: 0;
  } 
}

@media only screen and (max-width: 650px) {
  .horizontal .cpm_box{
    width: calc(100vw/2 - 3rem);
    min-width: 160px;
    flex-shrink: 0;
  } 
}


/* in sidebar */
.r_sidebar .cpm_box {
  width: 180px;
}
.r_sidebar .cpm_box_in {

  display: flex;
  flex-direction: column;
  vertical-align: top;
  margin-bottom: 10px;
  text-align: left;
  margin-right: 13px;
  border-radius: 3px;
    box-shadow: none;
}

  
</style>
