import { ref } from "vue";

const list = ref([]);

export const usePopupStore = () => {
  
  const add = (message: string, type: string) => {
    const rand_id = Math.random().toString(36).substr(2, 5);
    list.value.push({
      message: message,
      type: type,
      id: rand_id
    });

    setTimeout(()=>{
      remove(rand_id);
    }, type == 'error' ? 5000 : 2000);

    return rand_id;
  }

  const error = (message: string) => {
    add(message, 'error');
  }

  const success = (message: string) => {
    add(message, 'success');
  }

  const remove = (id: string) => {
    list.value = list.value.filter((item) => item.id !== id);
  }
   
  return {list, add, remove, error, success};
}
