export default defineNuxtPlugin(async (nuxtApp) =>  {  

  if(import.meta.server) 
    return;

  window.addEventListener('pageshow', (event) => {
    if (event.persisted) {
      // TODO
    }
  });

  window.addEventListener('pagehide', (event) => {
    if (event.persisted) {
      // TODO
    }
  });

})