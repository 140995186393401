import { ref } from "vue";

const advList = ref([])

export const useAdvList = () => {

  const add = (row) => {    
    advList.value = row;
  }
 
 
  return {advList, add};
}
 