

export default defineNuxtPlugin(nuxtApp => {  
  
  var complains = {
    
    add: function(type, id) {

      return nuxtApp.$api.postComplain(type, id) 
      // .then((res) => {                 
      //   console.log('complained');       
      //   console.log(res);                 
      // })
      // .catch(function (error) { 
      //   console.log('save germ error');       
      //   console.log(error);                 
      // });  
  
      //return retVal;
    }, 
  } 

  return {
    provide: {
      complains: complains,
    },
  };

})