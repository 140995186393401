

export default defineNuxtPlugin(nuxtApp => {  
   
  
  const { $api } = useNuxtApp();

  const translate = async (element, id, type, lang, callback) => {

    var callback = callback || function () { };

    var res = await $api.getTranslate(id, type, lang);
    console.log(res);

    if (res) {
      element.innerHTML = res?.body;
    }

    callback();
 
  };

  return {
    provide: {
      translate: translate,
    },
  };

})