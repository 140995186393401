import CryptoJS from 'crypto-js'
 
export default defineNuxtPlugin(({ nuxtApp, vueApp }) => {


  const uniq = {
    code: function() {
      var randomString = Math.random().toString(36).substring(2, 15);
      // var apiKey = useApiSecret();
      // var apiKey = useApiSecret() ? useApiSecret() : useCookie('auth:api').value;
      var apiKey = useCookie('auth:api',{maxAge: 60 * 60 * 24 * 31 * 6}).value ? useCookie('auth:api',{maxAge: 60 * 60 * 24 * 31 * 6}) : useApiSecret();
      var guest = useAuth().getGuest();
      var string = randomString + apiKey.value + guest;
      var value = CryptoJS.SHA256(string).toString()
      return {code: value, rnd: randomString}
    }
  };

  return {
    provide: {
      uniq: uniq,
    },
  };

});
