
export default defineNuxtPlugin((nuxtApp) => {
 
  // if(import.meta.client) {
  //   // window.addEventListener('error', (event) => {
  //   //   console.log('error', event)
  //   // })
 


  //   window.onerror = (message, source, lineno, colno, error) => {
  //     const errorData = {
  //       message,
  //       source,
  //       lineno,
  //       colno,
  //       stack: error?.stack
  //     };

  //     console.log('lof-error', errorData);
  //   }


  // }

})


 