export default defineNuxtPlugin(nuxtApp => {  
 
  const { gtag } = useGtag()

  var ga = {

    allowed: true,

    init: function() {
      // TODO useAuth().user
      // if($user.getPresets()?.length){
      //   for(let p of $user.getPresets()){
      //     if(p.name == 'enable_secure_analytics'){
      //       this.allowed = (p.val == '1' ? true : false)
      //     }
      //   }
      // }
    },
 

    event: (name, params) => {
      ga.init();
      if(ga.allowed){
        gtag('event', name, params)
      }
    },
  

    showSignupPopup: function() {
      this.event('show', {
        'event_category': 'signup_popup'
      });
    },

    useSignupPopup: function() {
      this.event('use', {
        'event_category': 'signup_popup'
      });
    },

    successSignupPopup: function() {
      this.event('signup_success', {
        'event_category': 'signup_popup'
      });
    },

    addDiary: function() {
      this.event('create_diary', {
        'event_category': 'create_diary'
      });
    },

    addGrower: function() {
      this.event('create_grower', {
        'event_category': 'create_an_account'
      });
    },

    searchFullTextExplore: function(q) {

    },

    searchFullTextGrowers: function(q) {

    },

    videoPlay: function(id) {
      this.event('play', {
        'event_category': 'Videos',
        'event_label': 'Diaries',
        'value': id
      });
    },

    clickBanner: function(type) {
      this.event('click', {
        'event_category': 'banner_click',
        'event_label': type
      });
    },

    clickFeedTag: function(tag) {
      this.event('click', {
        'event_category': 'feed_tag',
        'event_label': tag
      });
    },

    eventGA: function(type, category, val) {
      this.event(type, {
        'event_category': category ? category : 'action',
        'event_label': val
      });
    },
 

  }  
 

   
  return {
    provide: {
      ga: ga,
    },
  };

})