import { ref } from "vue";



const followCommon = ref({
  user_id: null,
  limit: 20,
  loaded: false,
  total: 0,
  start: 0,
  sort: 'update',
  items: []
})

export const useFollowCommon = () => {
  // const { $api } = useNuxtApp()
  // const load = async () => {    
  //   let res = await $api.getGrowerFollowingCommon(useAuth().getId(), followCommon.value.start, followCommon.value.limit, followCommon.value.sort)    
  //   followCommon.value.loaded = true
  //   followCommon.value.total = res.total
  //   followCommon.value.items = res.items_follow || [];
  //   followCommon.value.start = followCommon.value.start + followCommon.value.limit    
  //   return true; 
  // }

  const addRow = (item) => {
    item.content_id = item.id;
    item.avatar_small = item.avatar;
    followCommon.value.items.unshift(item);
  }

  const deleteRow = (item) => {
    item.content_id = item.id;
    console.log('followCommon.value.items', followCommon.value.items);
    console.log('item', item);
    for(var i in followCommon.value.items)
      if(followCommon.value.items[i].type == item.type && followCommon.value.items[i].content_id == item.content_id)
        followCommon.value.items.splice(i,1);             
  }

  const clear = () => {
    followCommon.value.user_id = null;
    followCommon.value.start = 0;
    followCommon.value.loaded = false;
    followCommon.value.items = [];
  }
 
  return {followCommon, addRow, deleteRow, clear};
}



const followBookmark = ref({
  user_id: null,
  limit: 20,
  loaded: false,
  total: 0,
  start: 0,
  sort: 'update',
  items: []
})

export const useFollowBookmark = () => {
  // const { $api } = useNuxtApp()
  // const load = async () => {    
  //   let res = await $api.getGrowerFollowingBookmark(useAuth().getId(), followBookmark.value.start, followBookmark.value.limit, followBookmark.value.sort)    
  //   followBookmark.value.loaded = true
  //   followBookmark.value.total = res.total
  //   followBookmark.value.items = res.items_bookmark || []
  //   followBookmark.value.start = followBookmark.value.start + followBookmark.value.limit     
  // }

  const addRow = (item) => {
    item.content_id = item.id;
    item.avatar_small = item.avatar;
    followBookmark.value.items.unshift(item);
  }

  const deleteRow = (item) => {
    item.content_id = item.id; 
    for(var i in followBookmark.value.items)
      if(followBookmark.value.items[i].type == item.type && followBookmark.value.items[i].content_id == item.content_id)
        followBookmark.value.items.splice(i,1);
  }

  const clear = () => {
    followBookmark.value.user_id = null;
    followBookmark.value.start = 0;
    followBookmark.value.loaded = false;
    followBookmark.value.items = [];
  }

  return {followBookmark, addRow, deleteRow, clear};
}



// export const state = () => ({
//     limit: 20,
  
//     commonLoaded: false,    
//     common: [],
//     commonStart: 0,
//     commonTotal: 0,
//     commonSort: 'update',
  
//     bookmarkLoaded: false,
//     bookmark: [],
//     bookmarkStart: 0,
//     bookmarkTotal: 0,
//     bookmarkSort: 'update',
//   })
  
//   export const mutations = {
//     setCommonLoaded(state, data) {    
//       state.commonLoaded = data;
//     },
//     setBookmarkLoaded(state, data) {    
//       state.bookmarkLoaded = data;
//     },
//     setLimit(state, data) {    
//       state.limit = data;
//     },
//     addCommon(state, data) {
//       state.common = [data].concat(state.common);
//     },        
//     replaceCommon(state, data) {      
//       state.common = data;    
//     },
//     setCommon(state, data, replace) {  
//       if(state.commonStart == 0 || replace)      
//         state.common = data;
//       else
//         state.common = state.common.concat(data);
//     },
//     setCommonTotal(state, data) {        
//       state.commonTotal = data;
//     },
//     setCommonStart(state, data) {        
//       state.commonStart = data;
//     },
//     setCommonSort(state, data) {        
//       state.commonSort = data;
//     },
//     addBookmark(state, data) {
//       state.bookmark = [data].concat(state.bookmark);
//     },    
//     replaceBookmark(state, data) {      
//       state.bookmark = data;    
//     },    
//     setBookmark(state, data, replace) {          
//       if(state.bookmarkStart == 0 || replace)      
//         state.bookmark = data;
//       else
//         state.bookmark = state.bookmark.concat(data);
//     },
//     setBookmarkTotal(state, data) {    
//       state.bookmarkTotal = data;
//     },
//     setBookmarkStart(state, data) {    
//       state.bookmarkStart = data;
//     },
//     setBookmarkSort(state, data) {    
//       state.bookmarkSort = data;
//     },
//   }
  
//   export const actions = {    
//     add({commit, state}, ev) {       
//       var r = {};
//       // if(ev.type == 'diary'){
//         r.id = false;
//         r.content_id = ev.data.id;
//         r.name = ev.data.name;
//         r.link = ev.data.link;
//         r.avatar_small = ev.data.avatar_small;
//       // }
  
//       r.type = ev.type;
//       r.cnt_update = 0; 
  
//       commit('addCommon', r);
  
//     },
//     delete({commit, state}, ev) {       
              
//       // if(ev.type == 'diary'){
//       var id = ev.data.id;
//       // }
//       var common = JSON.parse(JSON.stringify(state.common)) ;
//       var bookmark = JSON.parse(JSON.stringify(state.bookmark)) ;    
  
//       for(var i in common)
//         if(common[i].type == ev.type && common[i].content_id == id)
//           common.splice(i,1);             
  
//       for(var i in bookmark)
//         if(bookmark[i].type == ev.type && bookmark[i].content_id == id)
//           bookmark.splice(i,1);                      
       
//       commit('replaceCommon', common, true);        
//       commit('replaceBookmark', bookmark, true);        
         
//     },
//     loadCommon({commit, state}) { 
  
//       if(!useAuth().getId()){
//         commit('setCommonLoaded', true);
//         return;
//       }      
  
//       this.$api.getGrowerFollowingCommon(useAuth().getId(), state.commonStart, state.limit, state.commonSort)
//       .then((res) => { 
//         commit('setCommonLoaded', true);
//         commit('setCommonTotal', res.total);
//         commit('setCommon', res.items_follow);  
//         commit('setCommonStart', state.commonStart + state.limit);
//       })
//       .catch((error) => { 
//         console.log('error');
//       })     
//     }, 
//     loadBookmark({commit, state}) {   
   
//       if(!useAuth().getId()){
//         commit('setBookmarkLoaded', true);
//         return;
//       }      
  
//       this.$api.getGrowerFollowingBookmark(useAuth().getId(), state.bookmarkStart, state.limit, state.bookmarkSort)
//       .then((res) => { 
//         commit('setBookmarkLoaded', true);
//         commit('setBookmarkTotal', res.total);
//         commit('setBookmark', res.items_follow);
//         commit('setBookmarkStart', state.bookmarkStart + state.limit);
//       })
//       .catch((error) => { 
//         console.log('error');
//       })     
//     }, 
//   }
  
  
//   export const getters = {   
//     limit: s => s.limit,
//     commonLoaded: s => s.commonLoaded,  
//     common: s => s.common,
//     commonTotal: s => s.commonTotal,
//     commonStart: s => s.commonStart,
//     commonSort: s => s.commonSort,
//     bookmarkLoaded: s => s.bookmarkLoaded,
//     bookmark: s => s.bookmark,
//     bookmarkTotal: s => s.bookmarkTotal,
//     bookmarkStart: s => s.bookmarkStart,
//     bookmarkSort: s => s.bookmarkSort,
//   }
   