

export default defineNuxtPlugin(nuxtApp => {  
  
  var progressBarContent = {
  
    calcWeekGer: function(data: any){
      return this.calcWeekGerVegFlo(data);
    },
    calcWeekVeg: function(data: any){
      return this.calcWeekGerVegFlo(data);
    },
    calcWeekFlo: function(data: any){
      return this.calcWeekGerVegFlo(data);
    },
    calcWeekGerVegFlo: function(data: any){
    

      // photos
      var cnt_photo = data?.items_photo?.filter((p)=>{ return !p?.is_remove}).length ?? 0;
      if(cnt_photo < 1){
        var state_photos = 'bad';
      }else if(cnt_photo < 4){
        var state_photos = 'normal';
      }else{
        var state_photos = 'good';
      }

      // setup


      var cnt_conditions = 0;
      // $('.parameters .parameters_item .input_edit:visible,.parameters .parameters_item input.input_edit[name=smell]').each(function(){
      //   var vl = $(this).val();
      //   if(vl && vl.length && vl != '0'){
      //     cntParam++;
      //     // console.log($(this));
      //     // console.log(cntParam);
      //   }
      // });


      var conditions = [
        'air_hum',
        'air_temp',
        'co2',
        'height',
        'lamp_distance',
        'light',
        'night_air_temp',
        'ph',
        'pot_size',
        'ppm',
        'smell',
        'solution_temp',
        'substrate_temp',
        'tds',
        'watering_volume',
      ];
 

      conditions.forEach(element => {
        if(data.faza == 1 || data.faza == 0){
          if(data?.props[element]){
            cnt_conditions++;
          }
        }
      });

      console.log('data?.props', data?.props);
      console.log('cnt_conditions', cnt_conditions);

      if(data.faza == '-1'){
        if(data?.props['germ_date']){
          cnt_conditions = 10;
        }
      }

      if(cnt_conditions < 4){
        var state_conditions = 'bad';
      }else if(cnt_conditions < 6){
        var state_conditions = 'normal';
      } else {
        var state_conditions = 'good';
      }

      // nutrients

      var state_nutrients = 'bad';
      if(data.faza == '1'){

        var cnt_nutrients = 0;
        if(data.item_flowering?.items_nutrient?.filter((p)=>{ return !p?.is_remove}).length > 0){
          data.item_flowering.items_nutrient.filter((p)=>{ return !p?.is_remove}).forEach(element => {
            if(element.val){
              cnt_nutrients++;
            }
          });
        }

        if(cnt_nutrients > 0){
          var state_nutrients = 'good';
        }else{
          var state_nutrients = 'bad';
        }

      } 

      // comment
 
 
      var text = data?.text ?? '' 

      if(text.length < 1){
        var state_comment = 'bad'
      }else if(text.length < 140){
        var state_comment = 'normal'
      }else if(text.length >= 140){
        var state_comment = 'good'
      }else{
        state_comment = 'bad';
      }


      var ret = {
        conditions: state_conditions,
        photos: state_photos,
        nutrients: state_nutrients,
        comment: state_comment,
      };
      return ret;

    },
    calcWeekHar: function(data: any){
      var state_strain = 'bad';
      var state_result = 'bad';
      var state_photos = 'bad';
      var state_comment = 'bad';
      var state_nutrients = 'bad';



      // result


      var cnt_result = 0; 

      if(data.props.harvest_wet_weight*1>0){
        cnt_result++;
      }
      if(data.props.harvest_plant*1>0){
        cnt_result++;
      }
      if(data.props.harvest_watt*1>0){
        cnt_result++;
      }
      if(data.props.harvest_space*1>0){
        cnt_result++;
      }

      console.log('cnt_result', cnt_result);


      if(cnt_result < 1){
  			var state_result = 'bad';
  		}else if(cnt_result < 4){
  			var state_result = 'normal'; 
      }else{
  			var state_result = 'good'; 
      }


      // strain
 

      var cnt_strain = 0; 

      if(data.item_harvest.item_review_seed?.props?.growing && data.item_harvest.item_review_seed.props.growing*1>0){
        cnt_strain++;
      }
      if(data.item_harvest.item_review_seed?.props?.resistance && data.item_harvest.item_review_seed.props.resistance*1>0){
        cnt_strain++;
      }
      if(data.item_harvest.item_review_seed?.n_general && data.item_harvest.item_review_seed.n_general*1>0){
        cnt_strain++;
      }
      if(data?.item_harvest?.item_review_seed.text?.length > 0){
        cnt_strain++;
      }

      if(cnt_strain < 1){
  			var state_strain = 'bad';
  		}else if(cnt_strain < 4){
  			var state_strain = 'normal'; 
      }else{
  			var state_strain = 'good'; 
      }




      // photos
      var cnt_photo = data?.items_photo?.filter((p)=>{ return !p?.is_remove}).length ?? 0;
      if(cnt_photo < 1){
        var state_photos = 'bad';
      }else if(cnt_photo < 4){
        var state_photos = 'normal';
      }else{
        var state_photos = 'good';
      }





      // comment 
      var text = data?.text ?? '' 

      if(text.length < 1){
        var state_comment = 'bad'
      }else if(text.length < 140){
        var state_comment = 'normal'
      }else if(text.length >= 140){
        var state_comment = 'good'
      }else{
        state_comment = 'bad';
      }
 
      var nutrients_done = data.item_harvest?.items_nutrient?.filter((p)=>{ 
        return p.comment?.length > 0 && p.items.filter((pi)=>{ return pi.n_general>0}).length > 0
      }).length;

      if(!nutrients_done){
        var state_nutrients = 'bad';
      }else{
        var state_nutrients = 'good';
      }

      var ret = {
        strain: state_strain,
        result: state_result,
        photos: state_photos,
        comment: state_comment,
        nutrients: state_nutrients,
      };
      return ret;
    }
  }

  return {
    provide: {
      progressBarContent: progressBarContent,
    },
  };

})