import CryptoJS from 'crypto-js'
 
export default defineNuxtPlugin(({ nuxtApp, vueApp }) => {
  if (import.meta.server) {

    // var key = CryptoJS.enc.Hex.parse("0123456789abcdef0123456789abcdef");
    // var iv =  CryptoJS.enc.Hex.parse("abcdef9876543210abcdef9876543210");
    // var iv = CryptoJS.enc.Hex.parse("101112131415161718191a1b1c1d1e1f");

    const userId = useAuth().getGuest() 
    const uniqsecret = 'ecbea9568b4b0c511b3f4737beb08448371be87c186bf27308dd06d935f89e4e';
    const key = CryptoJS.AES.encrypt(userId, uniqsecret, {iv: ''}).toString();

    // const key = CryptoJS.HmacSHA512(userId, uniqsecret).toString();
    useCookie('auth:api',{maxAge: 60 * 60 * 24 * 31 * 6}).value = key;

    const apiKey = useApiSecret();
    apiKey.value = key;
  }
});
