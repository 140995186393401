


export default defineNuxtPlugin(nuxtApp => {  

  var rand = {
  
    str: function(length){
      var length = length ? length : 5;
      return this.generatePassword(length, 'az');
    },
    generatePassword: function(length, type) {
      if(type == 'azAZ')
        var charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
      else if(type == 'az') 
        var charset = "abcdefghijklmnopqrstuvwxyz";
      else 
        var charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      var retVal = "";
      for (var i = 0, n = charset.length; i < length; ++i) {
          retVal += charset.charAt(Math.floor(Math.random() * n));
      }
      return retVal;
    }
  }
  

  return {
    provide: {
      rand: rand,
    },
  };

})