export default defineNuxtPlugin(nuxtApp => {  
 
  const presaver = (id, type) => {

    console.log('presave start', id, type);
     
    var obj = {
      id: id ? id : null,
      type: type ? type : null,
      setup: function(id, type) {
        console.log('presave setup', id, type);
        this.id = id;
        this.type = type;
      },
      set: function(data) {
        if(import.meta.client){
          localStorage.setItem(this.type + ':' + this.id, JSON.stringify(data));
        }
      },
      get: function() {
        if(import.meta.client){
          var data = localStorage.getItem(this.type + ':' + this.id);
          if(data){
            return JSON.parse(data);
          }
        } 
        return false;
      },
      exists: function() {
        if(import.meta.client){
          var data = localStorage.getItem(this.type + ':' + this.id);
          if(data){
            return true;
          }
        } 
        return false;
      },
      clear: function() {
        if(import.meta.client){
          localStorage.removeItem(this.type + ':' + this.id);
        }
      },
      clearType: function(type) {
        if(import.meta.client){
          const keys = Object.keys(localStorage);
          keys.forEach(key => {
            if (key.startsWith(type + ':')) {
              localStorage.removeItem(key);
            }
          });
        }
      },
    }

    return obj;

  }
   
        
 


  return {
    provide: {
      presaver: presaver,
    },
  };

})