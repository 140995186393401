// import { VueReCaptcha } from 'vue-recaptcha-v3';

export default defineNuxtPlugin((nuxtApp) => {


  // nuxtApp.vueApp.component('VueReCaptcha', VueReCaptcha)

//   if(import.meta.client){
//   nuxtApp.vueApp.use(VueReCaptcha, {
//     siteKey: '6Ldh-EAeAAAAAKo8vixoyNGuR2qg-vU6Jh20B5py',
//     loaderOptions: {
//       autoHideBadge: true, 
//     },
//   });
// }
});

