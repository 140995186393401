var db = {
  "&#039;": "'",   
  "&quot;": '\\"',   
  "&lt;": "<",   
  "&gt;": ">",     
};


const specCharsFilter = {  
  decode: function(str){

    if(typeof str === 'string' && str && str.length > 2){
     
      let find = [];
      let replace = []; 
      for(var i in db){
        replace.push(db[i]);
        let key = i;
        find.push(key);        
      } 

      for (var i = 0; i < find.length; i++)                  
        str = str.replace(new RegExp(find[i],"g"), replace[i]);
    }

    return str;
 
  }
}




export default defineNuxtPlugin(nuxtApp => {  

  return {
    provide: {
      specCharsFilter: specCharsFilter,
    },
  };

})